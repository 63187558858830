import LazyRoute from 'approot/shared/routes/lazy-route';
import {
  PRIVATE_CLASSES_PARAM,
  PRIVATE_MY_STUDENTS,
  PRIVATE_STUDENTS_IMPORT,
} from 'global/private-routes.constants';

const TeacherStudentRoutes = [
  {
    path: [
      PRIVATE_MY_STUDENTS,
      `${PRIVATE_CLASSES_PARAM}${PRIVATE_MY_STUDENTS}`,
    ],
    component: LazyRoute(
      () => import('approot/teacher-student/teacher-students'),
      true,
      false
    ),
  },
  {
    path: [
      `${PRIVATE_CLASSES_PARAM}${PRIVATE_STUDENTS_IMPORT}`,
      PRIVATE_STUDENTS_IMPORT,
    ],
    component: LazyRoute(
      () => import('approot/teacher-student/teacher-students-import'),
      true,
      false
    ),
  },
];

export default TeacherStudentRoutes;
