import { ReactNode, ButtonHTMLAttributes } from 'react';

import Spinner from 'approot/shared/spinner/spinner';
import {
  SecondaryButton,
  SecondaryButtonIcon,
} from 'approot/shared/styles/buttons/secondary-button.style';
import { ButtonBaseProps } from './base-button.style';

type LoadingButtonProps = {
  children: ReactNode;
  isLoading: boolean;
  isDisabled?: boolean;
  onClick?: () => void;
} & ButtonHTMLAttributes<HTMLButtonElement> &
  ButtonBaseProps;

export const SecondaryLoadingButton = (
  props: LoadingButtonProps & {
    isIconAndText?: boolean;
    isIcon?: boolean;
  }
) => {
  const {
    children,
    isLoading,
    isDisabled,
    isIcon,
    isIconAndText,
    ...rest
  } = props;
  return isIcon ? (
    <SecondaryButtonIcon disabled={isDisabled} {...rest}>
      {isLoading ? (
        <>
          {' '}
          <Spinner />
        </>
      ) : (
        children
      )}
    </SecondaryButtonIcon>
  ) : (
    <SecondaryButton $withIcon={isIconAndText} disabled={isDisabled} {...rest}>
      {children}
      {isLoading && (
        <>
          {' '}
          <Spinner />
        </>
      )}
    </SecondaryButton>
  );
};
