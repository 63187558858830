import { makeVar } from '@apollo/client';

import {
  SESSION_IS_STALE_IN_SECONDS_DEV,
  SESSION_IS_STALE_IN_SECONDS_PROD,
} from 'global/constants';

export const timeoutSeconds =
  process.env.NODE_ENV === 'development'
    ? SESSION_IS_STALE_IN_SECONDS_DEV
    : SESSION_IS_STALE_IN_SECONDS_PROD;

// Ensures we don't trigger the inactive timer if it's not been set
const DEFAULT_TIMER_STARTED_AT = 1000000000000000;

export const sessionTimerVar = makeVar<{
  timeoutSeconds: number;
  timerStartedAt: number;
}>({
  timeoutSeconds,
  timerStartedAt: DEFAULT_TIMER_STARTED_AT,
});

export const isStaleSessionModalOpenVar = makeVar<boolean>(false);

export const resetSessionTimer = () => {
  sessionTimerVar({
    timeoutSeconds,
    timerStartedAt: Date.now(),
  });
};
