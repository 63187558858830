import {
  EVENT_ACTION_FOLLOW,
  EVENT_ACTION_DISMISS,
  EVENT_ACTION_CLOSE,
} from 'approot/shared/data-tracker/data-events.constants';

export enum ModalKey {
  JOIN = 'join',
  SIGNIN = 'signin',
  DENIED = 'denied',
  ADMIN = 'admin',
  INACTIVE_ACCOUNT = 'inactive-account',
  STUDENT_SIGNIN = 'studentsignin',
  CHOOSE_USER_JOIN = 'choose-user-join',
  CHOOSE_USER_SIGNIN = 'choose-user-signin',
  NONE = 'none',
}

export const KEEP_OPEN_ON_LOAD_MODAL_IDS = [
  ModalKey.JOIN,
  ModalKey.SIGNIN,
  ModalKey.STUDENT_SIGNIN,
] as const;

export type ModalProps = {
  isVisible: boolean;
  closeModal: () => void;
};

export type PaywallModal =
  | ModalKey.DENIED
  | ModalKey.JOIN
  | ModalKey.ADMIN
  | ModalKey.SIGNIN
  | ModalKey.INACTIVE_ACCOUNT
  | ModalKey.NONE;

export type KeepOpenOnLoadModal = typeof KEEP_OPEN_ON_LOAD_MODAL_IDS[number];

export type CloseModalOptions = {
  trackAs:
    | typeof EVENT_ACTION_DISMISS
    | typeof EVENT_ACTION_FOLLOW
    | typeof EVENT_ACTION_CLOSE;
  trackingLabel: string;
  trackingCategory?: string;
  followAction?: string;
};

export type CloseModal = (options?: CloseModalOptions) => void;
