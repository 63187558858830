import { useCallback, useContext } from 'react';

import {
  SnackbarNotificationContext,
  NotificationMode,
} from 'approot/shared/snackbar-notification/snackbar-notification.context';

export const useSnackbar = () => {
  const { setDisplayNotification, hideNotification } = useContext(
    SnackbarNotificationContext
  );

  const showSuccess = useCallback(
    (message: string, options?: { autoHide?: boolean }) => {
      setDisplayNotification(message, options);
    },
    [setDisplayNotification]
  );

  const showError = useCallback(
    (message: string, options?: { autoHide?: boolean }) => {
      setDisplayNotification(message, {
        autoHide: options?.autoHide,
        mode: NotificationMode.Error,
      });
    },
    [setDisplayNotification]
  );

  return {
    showSuccess,
    showError,
    hide: hideNotification,
  };
};
