import { useEffect } from 'react';
import {
  LDClient,
  LDSingleKindContext,
  useLDClient,
} from 'launchdarkly-react-client-sdk';

import useUserData from 'approot/shared/user/user-data';
import { getUserData } from 'approot/shared/api/auth/auth';
import { getStudentData } from 'approot/student-class-to-do/student-class-to-do.utils';
import { getStudentUserData } from 'approot/shared/api/auth/student-user-auth';

export const GUEST_KEY = 'guest';
export const USER_KIND = 'user';

export function initialiseLdUser(
  user?: {
    id: number;
    featureFlags: {
      groups: string[];
    };
  } | null
): LDSingleKindContext {
  const flags = user?.featureFlags;
  if (flags) {
    return {
      kind: USER_KIND, // this is the default value if not set
      key: user?.id.toString(),
      groups: flags.groups,
    };
  }

  return {
    kind: USER_KIND, // this is the default value if not set
    key: GUEST_KEY,
  };
}

export function getLaunchDarklyUserContext() {
  // These all read from local storage
  const userData = getUserData();
  const studentUserData = getStudentUserData();
  const studentClassData = getStudentData();

  if (studentClassData?.teacher) {
    return {
      id: studentClassData.teacher.id,
      featureFlags: studentClassData.teacher.featureFlags,
    };
  }

  if (studentUserData?.classId) {
    const currentClass = studentUserData.studentUser.currentClasses.find(
      c => c.id === studentUserData.classId
    );
    if (currentClass?.teacherId) {
      return {
        id: currentClass?.teacherId,
        featureFlags: currentClass?.featureFlags,
      };
    }
  }

  if (userData?.user) {
    return {
      id: userData.user.id,
      featureFlags: userData.user.featureFlags,
    };
  }
}

export function handleUserChangeIfNeeded(ldClient: LDClient | undefined) {
  if (ldClient) {
    const ldUser = ldClient.getContext();

    if (ldUser) {
      const newUser = initialiseLdUser(getLaunchDarklyUserContext());
      if (ldUser.key !== newUser.key) {
        console.log('changing ld user to', newUser);
        ldClient.identify({
          ...newUser,
        });
      }
    }
  }
}

const GlobalLaunchDarkly = () => {
  const { isSignedInReactive, isStudentSignedInReactive } = useUserData();
  const ldClient = useLDClient();

  useEffect(() => {
    handleUserChangeIfNeeded(ldClient);
  }, [isSignedInReactive, isStudentSignedInReactive, ldClient]);

  return <></>;
};

export default GlobalLaunchDarkly;
