import LazyRoute from 'approot/shared/routes/lazy-route';
import {
  ACCOUNT_BILLING,
  ACCOUNT_PROFILE,
  ACCOUNT_REFER,
  ACCOUNT_STAFF,
  ACCOUNT_STUDENT_DIRECTORY,
} from './account.constants';

const AUTHENTICATED_ROUTE = true;

const AccountTabsOutletRoutes = [
  {
    path: ACCOUNT_PROFILE,
    component: LazyRoute(
      () => import('./profile/profile'),
      AUTHENTICATED_ROUTE
    ),
  },
  {
    path: ACCOUNT_STAFF,
    component: LazyRoute(() => import('./people/people'), AUTHENTICATED_ROUTE),
  },
  {
    path: ACCOUNT_STUDENT_DIRECTORY,
    component: LazyRoute(
      () => import('./student-directory/student-directory'),
      AUTHENTICATED_ROUTE
    ),
  },
  {
    path: ACCOUNT_BILLING,
    component: LazyRoute(
      () => import('./billing/billing'),
      AUTHENTICATED_ROUTE
    ),
  },
  {
    path: ACCOUNT_REFER,
    component: LazyRoute(() => import('./refer/refer'), AUTHENTICATED_ROUTE),
  },
];

export default AccountTabsOutletRoutes;
