import {
  QueryHookOptions,
  useQuery,
  MutationHookOptions,
  MutationTuple,
  useMutation,
  useLazyQuery,
  OperationVariables,
  QueryResult,
  QueryTuple,
  ApolloError,
  DocumentNode,
} from '@apollo/client';
import { OperationDefinitionNode } from 'graphql';
import { useRef, useEffect, useMemo } from 'react';

import {
  GRAPHQL_REQUEST_TRACE_ID,
  POLLING_QUERIES,
  GRAPHQL_POLLING_REQUEST,
} from 'global/constants';
import { getToken } from 'approot/shared/api/auth/auth';
import { AnyObject } from 'webclient.constants';
import { generateTraceId } from 'approot/shared/api/auth/generate-trace-id';
import { resetSessionTimer } from 'lib/session-stale-monitor.apollo';

export const useLoggingQuery = <
  TData extends any,
  TVariables extends OperationVariables | null
>(
  query: DocumentNode,
  options?: QueryHookOptions<TData, TVariables>,
  authenticate: boolean = true
): QueryResult<TData, TVariables> => {
  const generatedTraceId = useMemo(() => generateTraceId(), []);

  // There is a bug where error is lost after re-renders, so we hold onto it in case it disappears
  // TODO: remove the logic and return the straight response when upgrading Apollo
  const latestError = useRef<ApolloError>();
  const { data, loading, error, ...response } = useQuery<TData, TVariables>(
    query,
    setupOptions(generatedTraceId, options, authenticate)
  );
  useEffect(() => {
    if (error) {
      latestError.current = error;
    }
  }, [error]);

  useEffect(() => {
    if (
      loading &&
      !POLLING_QUERIES.includes(
        (query.definitions[0] as OperationDefinitionNode)?.name?.value || ''
      )
    ) {
      resetSessionTimer();
    }
  }, [loading, query]);

  return {
    data,
    loading,
    error: !data && !loading && !error ? latestError.current : error,
    ...response,
  };
};

export const useLoggingLazyQuery = <
  TData extends any,
  TVariables extends OperationVariables | null
>(
  query: DocumentNode,
  options?: QueryHookOptions<TData, TVariables>,
  authenticate: boolean = true,
  tokenOverride?: string
): QueryTuple<TData, TVariables> => {
  const generatedTraceId = useMemo(() => generateTraceId(), []);

  const [apolloQuery, apolloResponse] = useLazyQuery<TData, TVariables>(
    query,
    setupOptions(generatedTraceId, options, authenticate, tokenOverride)
  );

  useEffect(() => {
    if (apolloResponse.loading) {
      resetSessionTimer();
    }
  }, [apolloResponse]);

  return [apolloQuery, apolloResponse];
};

export const useLoggingMutation = <
  TData extends any,
  TVariables extends OperationVariables | null
>(
  mutation: DocumentNode,
  options?: MutationHookOptions<TData, TVariables>,
  authenticate: boolean = true,
  tokenOverride?: string
): MutationTuple<TData, TVariables> => {
  const generatedTraceId = useMemo(() => generateTraceId(), []);

  const [apolloMutation, apolloResponse] = useMutation<TData, TVariables>(
    mutation,
    setupMutationOptions(generatedTraceId, options, authenticate, tokenOverride)
  );

  useEffect(() => {
    if (apolloResponse.loading) {
      resetSessionTimer();
    }
  }, [apolloResponse]);

  return [apolloMutation, apolloResponse];
};

export const setupOptions = <
  TData extends any,
  TVariables extends OperationVariables | null
>(
  traceId: string,
  options?: QueryHookOptions<TData, TVariables>,
  authenticate: boolean = true,
  tokenOverride?: string
) => {
  const token = authenticate && (tokenOverride ? tokenOverride : getToken());
  const newHeaders: AnyObject = {
    [GRAPHQL_REQUEST_TRACE_ID]: traceId,
    ...(options?.pollInterval
      ? {
          [GRAPHQL_POLLING_REQUEST]: true,
        }
      : {}),
  };

  if (token) {
    newHeaders.authorization = `Bearer ${token}`;
  }

  let newOptions: QueryHookOptions<TData, TVariables>;

  const missingToken = authenticate && !token;

  if (options) {
    const { context, skip, ...rest } = options;
    const headers =
      context && context.headers
        ? { ...context.headers, ...newHeaders }
        : newHeaders;

    const newSkip = skip ? skip || missingToken : missingToken;
    newOptions = { ...rest, context: { ...context, headers }, skip: newSkip };
  } else {
    newOptions = { context: { headers: newHeaders }, skip: missingToken };
  }
  return newOptions;
};

export const setupMutationOptions = <
  TData extends any,
  TVariables extends OperationVariables | null
>(
  traceId: string,
  options?: MutationHookOptions<TData, TVariables>,
  authenticate: boolean = true,
  tokenOverride?: string
) => {
  const token = authenticate && (tokenOverride ? tokenOverride : getToken());
  const newHeaders: AnyObject = {
    [GRAPHQL_REQUEST_TRACE_ID]: traceId,
  };

  if (token) {
    newHeaders.authorization = `Bearer ${token}`;
  }

  let newOptions: MutationHookOptions<TData, TVariables>;

  if (options) {
    const { context, ...rest } = options;
    const headers =
      context && context.headers
        ? { ...context.headers, ...newHeaders }
        : newHeaders;

    newOptions = { ...rest, context: { ...context, headers } };
  } else {
    newOptions = { context: { headers: newHeaders } };
  }
  return newOptions;
};
