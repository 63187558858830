import { useReactiveVar } from '@apollo/client';

import { useLoggingLazyQuery } from 'lib/apollo/apollo-hooks';
import { ExtendSession } from './__generated__/ExtendSession';
import { EXTEND_SESSION } from './signin-refresh-session.graphql';
import { studentAuthApolloContextOption } from 'approot/student-class-to-do/student-class-to-do.utils';
import {
  isStaleSessionModalOpenVar,
  resetSessionTimer,
} from 'lib/session-stale-monitor.apollo';
import { useSnackbar } from 'approot/shared/snackbar-notification/snackbar-notification.hooks';
import { isStudentSignedInVar } from 'approot/shared/user/user-data.apollo';

export const useExtendSession = () => {
  const isStudentSignedIn = useReactiveVar(isStudentSignedInVar);

  const { showError } = useSnackbar();

  const [extendSession, { loading }] = useLoggingLazyQuery<ExtendSession, null>(
    EXTEND_SESSION,
    {
      fetchPolicy: 'network-only',
      ...(isStudentSignedIn
        ? { context: studentAuthApolloContextOption() }
        : {}),
      onCompleted: () => {
        resetSessionTimer();
        isStaleSessionModalOpenVar(false);
      },
      onError: () => {
        showError(
          'Something went wrong keeping you signed in. Please refresh and try again.'
        );
      },
    }
  );

  return {
    extendSession,
    loading,
  };
};
