import { gql } from '@apollo/client';

import { userFragment } from 'approot/shared/user/user-data.graphql';
import { studentFragment } from 'approot/students/students.graphql';

export const SIGNIN = gql`
  query SignIn($input: SignInInput!) {
    signIn(input: $input) {
      token
      user {
        ...UserFragment
      }
      student {
        ...StudentFragment
      }
    }
  }
  ${userFragment}
  ${studentFragment}
`;

export const SIGNIN_PRECHECK = gql`
  query PreSignInCheck($input: PreSignInCheckInput!) {
    preSignInCheck(input: $input) {
      nswdetsso
      isIncorrectRegion
      region
    }
  }
`;

export const DIRECT_STUDENT_SIGNIN = gql`
  query DirectStudentSignIn($input: DirectStudentSignInInput!) {
    directStudentSignIn(input: $input) {
      token
      user {
        ...UserFragment
      }
      student {
        ...StudentFragment
      }
    }
  }
  ${userFragment}
  ${studentFragment}
`;

export const SIGN_OUT = gql`
  mutation SignOut {
    signOut
  }
`;
