import { ReactNode, useEffect } from 'react';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { useLocation, useNavigate } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';

import { client as apolloClient } from './apollo';
import {
  fetchUserIfSessionIsActive,
  fetchStudentUserIfSessionIsActive,
  checkIfSessionIsTaken,
} from 'apollo.utils';
import { dataTracker } from 'approot/shared/data-tracker/data-tracker';
import { useScrollMemory } from 'lib/screen';
import {
  getLaunchDarklyUserContext,
  initialiseLdUser,
} from 'approot/global-launch-darkly/global-launch-darkly';
import { SKIP_USER_QUERIES_URLS } from 'webclient.constants';
import { usePreloadStartupData } from 'approot/shared/graphql/startup-data/startup-data.hooks';
import { globalErrorResponseVar } from 'startup.apollo';
import { usePricingInfo } from 'approot/shared/graphql/pricing/pricing.hooks';

// Note: this class should only contain code
// that needs to run when the app starts up the first time

const StartUp = ({ children }: { children?: ReactNode }) => {
  usePreloadStartupData();
  usePricingInfo();

  const skipUserQuery = SKIP_USER_QUERIES_URLS.some(path =>
    window.location.pathname.includes(path)
  );

  useEffect(() => {
    // only run once
    if (!skipUserQuery) {
      fetchUserIfSessionIsActive(apolloClient.getClient());
      fetchStudentUserIfSessionIsActive(apolloClient.getClient());
    }

    dataTracker.setApolloClient(apolloClient);
    dataTracker.start();

    return () => {
      dataTracker.stop();
    };
    // eslint-disable-next-line
  }, []);

  return <>{children}</>;
};

// typed with any for now until this error is fixed
// https://github.com/launchdarkly/react-client-sdk/issues/223
export default withLDProvider<any>({
  clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_API_KEY || '',
  user: initialiseLdUser(getLaunchDarklyUserContext()),
})(StartUp);

// this causes re-renders so is not included within the startup component
export const ScrollMemoryMonitor = () => {
  useScrollMemory();
  return null;
};

// this causes re-renders so is not included within the startup component
export const SessionTakenMonitor = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const globalErrorResponse = useReactiveVar(globalErrorResponseVar);

  useEffect(() => {
    apolloClient.setLocation(location);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (globalErrorResponse) {
      checkIfSessionIsTaken(globalErrorResponse, location, navigate);
      globalErrorResponseVar(undefined);
    }
  }, [globalErrorResponse, location, navigate]);
  return null;
};
