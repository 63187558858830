import { useQuery, useReactiveVar } from '@apollo/client';

import { IsSignedIn } from './__generated__/IsSignedIn';
import { IS_SIGNED_IN, GET_USER } from './user-data.graphql';
import {
  ACCOUNT_STATUS_INACTIVE,
  ACCOUNT_STATUS_DELETED,
  ACCOUNT_SCHOOL,
} from 'global/constants';
import { InquisitiveAccountStatus } from 'global/graphql/scalars.type';
import { GetUser } from './__generated__/GetUser';
import { isSignedInVar, isStudentSignedInVar } from './user-data.apollo';

export function isAccountInactive(status: InquisitiveAccountStatus | null) {
  return (
    status === ACCOUNT_STATUS_INACTIVE || status === ACCOUNT_STATUS_DELETED
  );
}

const useUserData = () => {
  const { data } = useQuery<IsSignedIn>(IS_SIGNED_IN);

  const isSignedInReactive = useReactiveVar(isSignedInVar);
  const isStudentSignedInReactive = useReactiveVar(isStudentSignedInVar);

  const isSignedIn = !!(data && data.isSignedIn);
  const isStudentSignedIn = !!(data && data.isStudentSignedIn);
  const isAlreadySignedInOnce = !!(data && data.isAlreadySignedInOnce);

  const { data: userData } = useQuery<GetUser>(GET_USER, {
    skip: !isSignedIn,
    fetchPolicy: 'cache-only',
  });
  const user = userData && userData.user;
  const canEditSelfContentOnly = !!(
    user && user.permission.canManageSelfContentOnly
  );
  const isSchoolAdmin = !!(user && user.isSchoolAdmin);
  const isSchoolTeacher = !!(user && user.isSchoolTeacher);
  const isAccountExpired = !!(user && isAccountInactive(user.accountStatus));
  const isTeacherOrAdmin = isSchoolAdmin || isSchoolTeacher;
  const isAdminOnly = !isSchoolTeacher && isSchoolAdmin;
  const canDownloadSheets = isSignedIn && isTeacherOrAdmin;
  const canViewPaidContent = isSignedIn && isSchoolTeacher;
  const canBookmarkContent = isSignedIn && isSchoolTeacher;
  const canReferForCredit = user?.permission.showAccountReferralTab;
  const canEditContent = isSignedIn && !!user?.permission.canEditContent;
  const canEditActivities = isSignedIn && !!user?.permission.canEditActivities;
  const canPublishActivities =
    isSignedIn && !!user?.permission.canPublishActivities;
  const canUpdatePassword = isSignedIn && user?.permission.canUpdatePassword;
  const canBuildActivities =
    isSignedIn && !!user?.permission.canBuildActivities;

  return {
    user: user,
    isSignedIn,
    isStudentSignedIn,
    isStudentSignedInReactive,
    isSignedInReactive,
    isAlreadySignedInOnce,
    isSchoolAdmin,
    isSchoolTeacher,
    isTeacherOrAdmin,
    isAdminOnly,
    isAccountExpired,
    isInTrial: !!user?.isInTrial,
    isSchoolAccount: user?.accountType === ACCOUNT_SCHOOL,
    canEditSelfContentOnly,
    canDownloadSheets,
    canViewPaidContent,
    canBookmarkContent,
    canReferForCredit,
    canEditContent,
    canEditActivities,
    canPublishActivities,
    canBuildActivities,
    canUpdatePassword,
  };
};

export default useUserData;
