import accountRoutes from 'approot/account/account.routes';
import inviteRoutes from 'approot/invite/invite.routes';
import joinRoutes from 'approot/join/join.routes';
import moveSchoolRoutes from 'approot/account/move-school-account/move-school-account.routes';
import onBoardingRoutes from 'approot/join/on-boarding.routes';
import studentRoutes from 'approot/student-class-to-do/student.routes';
import teacherStudentRoutes from 'approot/teacher-student/teacher-student.routes';
import trialRoutes from 'approot/flows/trial/trial.routes';
import contactUsRoutes from 'approot/enterprise/contact/contact.routes';
import LazyRoute, { LazyRouteRegion } from 'approot/shared/routes/lazy-route';

import {
  PUBLIC_HOME,
  PUBLIC_LESSONS,
  PUBLIC_PRICING,
  PUBLIC_TERMS,
  PUBLIC_ABOUT,
  PUBLIC_GUIDED_RESEARCH,
  PUBLIC_VIDEO,
  PUBLIC_AUDIO,
  PUBLIC_SIGNIN,
  PUBLIC_RESET_PASSWORD,
  PUBLIC_FORGOT_PASSWORD,
  PUBLIC_BLOG,
  PUBLIC_PRIVACY,
  PUBLIC_COOKIES,
  PUBLIC_SESSION_TAKEN,
  PUBLIC_INTERACTIVE,
  PUBLIC_SEARCH,
  PUBLIC_SCOPE_AND_SEQUENCE,
  PUBLIC_NOT_FOUND,
  PUBLIC_NOT_AUTHORISED,
  PUBLIC_WORLD_TEACHERS_DAY_VIDEO,
  PUBLIC_GETTING_STARTED,
  PUBLIC_STIMULUS,
  PUBLIC_TEACHER_BENEFITS,
  PUBLIC_SCHOOL_BENEFITS,
  PUBLIC_LESSON_ACTIVITIES,
} from 'global/public-routes.constants';
import {
  PRIVATE_CREATE_STIMULUS,
  PRIVATE_EDIT_STIMULUS,
  PRIVATE_CREATE_STIMULUS_SEARCH,
  PRIVATE_CREATE,
  PRIVATE_CREATE_BY_TYPE,
  PRIVATE_PLANNER,
  PRIVATE_PLANNER_RESET_ERROR,
  PRIVATE_SSO_OAUTH,
  PRIVATE_SSO_PROMOTION,
  PRIVATE_CLASSES,
  PRIVATE_CLASSES_CREATE,
  PRIVATE_CLASSES_EDIT,
  PRIVATE_CLASSES_PARAM,
  PRIVATE_CLASS_TO_DO,
  PRIVATE_PLANNER_RECEIVE,
  PRIVATE_ANNOTATIONS,
  PRIVATE_INTERACTIVE_LESSON_CREATE,
  PRIVATE_INTERACTIVE_LESSON_EDIT,
  PRIVATE_EDIT,
  PRIVATE_INTERACTIVE_LESSON_PRESENT,
  PRIVATE_ASSISTANT,
  PRIVATE_INTERACTIVE_LESSON_DRAFT,
  PRIVATE_INTERACTIVE_ASSESSMENT_CREATE,
  PRIVATE_INTERACTIVE_ASSESSMENT_EDIT,
  PRIVATE_INTERACTIVE_ASSESSMENT_PRESENT,
  PRIVATE_INTERACTIVE_ASSESSMENT_DRAFT,
  PRIVATE_INTERACTIVE_LESSON_STUDENT_PRESENT,
  PRIVATE_INTERACTIVE_ASSESSMENT_STUDENT_PRESENT,
  PRIVATE_UPDATE_PASSWORD,
  PRIVATE_UPDATE_PASSWORD_CONFIRM,
  PRIVATE_ASSISTANT_CHAT,
  PRIVATE_SIGNIN_REFRESH_SESSION,
} from 'global/private-routes.constants';
import { RouteConfig } from './router.constants';
import contentPageRoutes from 'approot/shared/content-page/content-page.routes';
import { ASSISTANT_FEATURE_FLAG } from 'approot/assistant/assistant.constants';
import { subjectBrowserRoutes } from 'approot/subject-browser/subject-browser.routes';

const routes: RouteConfig[] = [];

routes.push({
  path: PUBLIC_WORLD_TEACHERS_DAY_VIDEO,
  component: LazyRoute(() => import('approot/video/video-static-promo')),
});

routes.push({
  path: [
    `${PRIVATE_CLASSES_PARAM}${PRIVATE_ASSISTANT}`,
    PRIVATE_ASSISTANT_CHAT,
  ],
  component: LazyRoute(
    () => import('approot/assistant/assistant'),
    true,
    false,
    ASSISTANT_FEATURE_FLAG
  ),
});

routes.push({
  path: PUBLIC_GETTING_STARTED,
  component: LazyRoute(() =>
    import('approot/video/video-static-getting-started')
  ),
});

routes.push({
  path: PUBLIC_NOT_FOUND,
  component: LazyRoute(() => import('approot/errors/not-found-page')),
});

routes.push({
  path: PUBLIC_NOT_AUTHORISED,
  component: LazyRoute(() => import('approot/errors/unauthorised-page')),
});

routes.push({
  path: PRIVATE_SSO_OAUTH,
  component: LazyRoute(() =>
    import('approot/shared/signin/signin-oauth/signin-oauth')
  ),
});

routes.push({
  path: PRIVATE_SSO_PROMOTION,
  component: LazyRoute(() =>
    import('approot/shared/signin/promotion-page/promotion')
  ),
});

routes.push({
  path: PUBLIC_GUIDED_RESEARCH,
  component: LazyRoute(() => import('approot/guidedresearch/guidedresearch')),
});

routes.push({
  path: PUBLIC_VIDEO,
  component: LazyRoute(() => import('approot/video/video')),
});

routes.push({
  path: PUBLIC_INTERACTIVE,
  component: LazyRoute(() => import('approot/interactive/interactive')),
});

routes.push({
  path: PUBLIC_AUDIO,
  component: LazyRoute(() => import('approot/video/video')),
});

routes.push({
  path: PUBLIC_TEACHER_BENEFITS,
  component: LazyRouteRegion({
    au: {
      importFn: () =>
        import('approot/enterprise/teacher-benefits/teacher-benefits'),
    },
    us: {
      importFn: () =>
        import('approot/enterprise/teacher-benefits/us/teacher-benefits'),
    },
  }),
});

routes.push({
  path: PUBLIC_SCHOOL_BENEFITS,
  component: LazyRouteRegion({
    au: {
      importFn: () =>
        import('approot/enterprise/school-benefits/school-benefits'),
    },
    us: {
      importFn: () =>
        import('approot/enterprise/school-benefits/us/school-benefits'),
    },
  }),
});

routes.push({
  path: PUBLIC_ABOUT,
  component: LazyRouteRegion({
    au: {
      importFn: () => import('approot/enterprise/about/about'),
    },
    us: {
      importFn: () => import('approot/enterprise/about/us/about'),
    },
  }),
});

routes.push({
  path: PUBLIC_PRICING,
  component: LazyRoute(() => import('approot/enterprise/pricing/pricing')),
});

routes.push({
  path: [PUBLIC_TERMS, PUBLIC_PRIVACY, PUBLIC_COOKIES],
  component: LazyRouteRegion({
    au: {
      importFn: () => import('approot/enterprise/terms-privacy/terms-privacy'),
    },
    us: {
      importFn: () =>
        import('approot/enterprise/terms-privacy/us/terms-privacy'),
    },
  }),
});

routes.push({
  path: PRIVATE_CREATE_STIMULUS_SEARCH,
  component: LazyRoute(
    () => import('approot/create-stimulus/create-stimulus-search'),
    true
  ),
});

routes.push({
  path: PRIVATE_CREATE_STIMULUS,
  component: LazyRoute(
    () => import('approot/create-stimulus/create-stimulus'),
    true
  ),
});

routes.push({
  path: PRIVATE_EDIT_STIMULUS,
  component: LazyRoute(
    () => import('approot/create-stimulus/edit-stimulus'),
    true
  ),
});

routes.push({
  path: PRIVATE_CREATE_BY_TYPE,
  component: LazyRoute(() => import('approot/create/create'), true),
});
routes.push({
  path: PRIVATE_CREATE,
  component: LazyRoute(() => import('approot/create/create'), true),
});

routes.push({
  path: PUBLIC_SEARCH,
  component: LazyRoute(() => import('approot/search/search')),
});

routes.push({
  path: PUBLIC_FORGOT_PASSWORD,
  component: LazyRoute(() =>
    import('approot/account/forgot-password/forgot-password')
  ),
});

routes.push({
  path: PUBLIC_RESET_PASSWORD,
  component: LazyRoute(() =>
    import('approot/account/reset-password/reset-password')
  ),
});

routes.push({
  path: PRIVATE_UPDATE_PASSWORD,
  component: LazyRoute(
    () => import('approot/account/update-password/update-password'),
    true
  ),
});

routes.push({
  path: PRIVATE_UPDATE_PASSWORD_CONFIRM,
  component: LazyRoute(
    () => import('approot/account/update-password/update-password-confirm'),
    true
  ),
});

routes.push({
  path: '/invoice/:invoiceId',
  component: LazyRoute(() => import('approot/account/invoice/invoice')),
});

routes.push({
  path: PUBLIC_SIGNIN,
  component: LazyRoute(() =>
    import('approot/shared/signin/signin-page/signin-page')
  ),
});

routes.push({
  path: PRIVATE_SIGNIN_REFRESH_SESSION,
  component: LazyRoute(() =>
    import(
      'approot/shared/signin/signin-refresh-session/signin-refresh-session-page'
    )
  ),
});

routes.push({
  path: [
    `${PUBLIC_BLOG}:year/:month/:day/:slug`,
    `${PUBLIC_BLOG}:slug`,
    `${PUBLIC_BLOG}/*`,
  ],
  component: LazyRoute(() => import('approot/blog/blog')),
});

routes.push({
  path: PUBLIC_SESSION_TAKEN,
  component: LazyRoute(() => import('approot/errors/session-taken-page')),
});

routes.push({
  path: `${PRIVATE_PLANNER_RECEIVE}/*`,
  component: LazyRoute(() => import('approot/planner/receive/receive')),
});

routes.push({
  path: [`${PRIVATE_CLASSES_PARAM}${PRIVATE_PLANNER}`, PRIVATE_PLANNER],
  component: LazyRoute(() => import('approot/planner/planner-layout')),
});

routes.push({
  path: PRIVATE_PLANNER_RESET_ERROR,
  component: LazyRoute(() =>
    import('approot/planner/manage/messages/planner-error-reset')
  ),
});

routes.push({
  path: PRIVATE_ANNOTATIONS,
  component: LazyRoute(() => import('approot/annotations/annotations'), true),
});

routes.push({
  path: [PRIVATE_CLASSES_CREATE, PRIVATE_CLASSES_EDIT],
  component: LazyRouteRegion({
    au: {
      importFn: () => import('approot/classes/class-form/class-form'),
      authenticate: true,
    },
    us: {
      importFn: () => import('approot/classes/class-form/class-form-us'),
      authenticate: true,
    },
  }),
});

routes.push({
  path: `${PRIVATE_CLASSES}/*`,
  component: LazyRoute(() => import('approot/classes/classes'), true),
});

routes.push(
  ...onBoardingRoutes,
  ...joinRoutes,
  ...trialRoutes,
  ...accountRoutes,
  // ...supportRoutes, TODO this should be done different now
  ...inviteRoutes,
  ...studentRoutes,
  ...teacherStudentRoutes,
  ...moveSchoolRoutes,
  ...contentPageRoutes,
  ...contactUsRoutes,
  ...subjectBrowserRoutes
);

routes.push({
  path: [PRIVATE_INTERACTIVE_LESSON_CREATE],
  component: LazyRoute(
    () => import('approot/activity-builder/lesson-builder-create'),
    true,
    false
  ),
});

routes.push({
  path: [PRIVATE_INTERACTIVE_LESSON_EDIT],
  component: LazyRoute(
    () => import('approot/activity-builder/lesson-builder'),
    true,
    false
  ),
});

routes.push({
  path: [PRIVATE_INTERACTIVE_LESSON_PRESENT],
  component: LazyRoute(
    () => import('approot/activity-builder/present/lesson-present'),
    true,
    false
  ),
});

routes.push({
  path: [PRIVATE_INTERACTIVE_LESSON_DRAFT],
  component: LazyRoute(
    () => import('approot/activity-builder/draft/activity-builder-draft'),
    true,
    false
  ),
});

routes.push({
  path: [PRIVATE_INTERACTIVE_ASSESSMENT_CREATE],
  component: LazyRoute(
    () =>
      import('approot/activity-builder/assessment/assessment-builder-create'),
    true,
    false
  ),
});

routes.push({
  path: [PRIVATE_INTERACTIVE_ASSESSMENT_EDIT],
  component: LazyRoute(
    () => import('approot/activity-builder/assessment/assessment-builder'),
    true,
    false
  ),
});

routes.push({
  path: [PRIVATE_INTERACTIVE_ASSESSMENT_PRESENT],
  component: LazyRoute(
    () =>
      import('approot/activity-builder/assessment/present/assessment-present'),
    true,
    false
  ),
});

routes.push({
  path: [PRIVATE_INTERACTIVE_ASSESSMENT_DRAFT],
  component: LazyRoute(
    () =>
      import('approot/activity-builder/assessment/assessment-builder-draft'),
    true,
    false
  ),
});

routes.push({
  path: [PRIVATE_INTERACTIVE_LESSON_STUDENT_PRESENT],
  component: LazyRoute(
    () =>
      import('approot/activity-builder/student/present/student-lesson-present'),
    true,
    true
  ),
});

routes.push({
  path: [
    `${PRIVATE_CLASSES_PARAM}${PUBLIC_LESSON_ACTIVITIES}`,
    PUBLIC_LESSON_ACTIVITIES,
  ],
  component: LazyRoute(() =>
    import('approot/activity-builder/present/lesson-present')
  ),
});

routes.push({
  path: [PRIVATE_INTERACTIVE_ASSESSMENT_STUDENT_PRESENT],
  component: LazyRoute(
    () =>
      import(
        'approot/activity-builder/student/present/student-assessment-present'
      ),
    true,
    true
  ),
});

routes.push({
  path: [`${PRIVATE_CLASSES_PARAM}${PRIVATE_CLASS_TO_DO}`, PRIVATE_CLASS_TO_DO],
  component: LazyRoute(() => import('approot/class-to-dos/class-to-dos-page')),
});

routes.push({
  path: [PUBLIC_SCOPE_AND_SEQUENCE],
  component: LazyRoute(() =>
    import('approot/scope-and-sequence/scope-and-sequence')
  ),
});
routes.push({
  path: `${PUBLIC_SCOPE_AND_SEQUENCE}/*`,
  component: LazyRoute(() =>
    import('approot/scope-and-sequence/scope-and-sequence-receive')
  ),
});

routes.push({
  path: [
    PUBLIC_LESSONS,
    `${PRIVATE_CLASSES_PARAM}${PUBLIC_LESSONS}`,
    `${PRIVATE_CLASSES_PARAM}${PUBLIC_LESSONS}${PRIVATE_EDIT}`,
  ],
  component: LazyRoute(() => import('approot/content-browser/content-browser')),
});

routes.push({
  path: [PUBLIC_STIMULUS, `${PRIVATE_CLASSES_PARAM}${PUBLIC_STIMULUS}`],
  component: LazyRoute(() =>
    import('approot/stimulus-browser/stimulus-browser')
  ),
});

routes.push({
  path: [PUBLIC_HOME],
  component: LazyRouteRegion({
    au: {
      importFn: () => import('approot/home/home'),
    },
    us: {
      importFn: () => import('approot/home/us/home'),
    },
  }),
});

routes.push({
  path: [PRIVATE_CLASSES_PARAM],
  component: LazyRouteRegion({
    au: {
      importFn: () => import('approot/home/home-authed'),
    },
    us: {
      importFn: () => import('approot/home/us/home-authed'),
    },
  }),
});

//Must come last:
routes.push({
  path: '*',
  component: LazyRoute(() => import('approot/errors/not-found-page')),
});

export default routes;
