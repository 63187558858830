import { createClient } from 'graphql-ws';

import { getToken } from 'approot/shared/api/auth/auth';
import { WEBSOCKET_ENDPOINT } from 'webclient.constants';
import { setWsConnectionStatus } from './ws-apollo';
import {
  getStudentToken,
  getStudentUserToken,
} from 'approot/student-class-to-do/student-class-to-do.utils';

function createWsClient() {
  let activeSocket: WebSocket;
  let pingPongTimeout: number | undefined = undefined;

  return createClient({
    on: {
      opened: socket => {
        activeSocket = socket as WebSocket;
      },
      connecting: () => {
        console.debug('ws: connecting');
      },
      connected: () => {
        console.debug('ws: onConnected');
        setWsConnectionStatus(true);
      },
      error: error => {
        console.debug('ws: onError', new Date(), error);
        setWsConnectionStatus(false);
      },
      closed: () => {
        console.debug('ws: onDisconnected');
        setWsConnectionStatus(false);
      },
      ping: (received: boolean) => {
        if (!received) {
          pingPongTimeout = window.setTimeout(() => {
            if (activeSocket.readyState === WebSocket.OPEN)
              activeSocket.close();
          }, 5_000);
        }
      },
      pong: (received: boolean) => {
        if (received) {
          clearTimeout(pingPongTimeout);
        }
      },
    },
    url: WEBSOCKET_ENDPOINT,
    lazy: true,
    shouldRetry: () => true,
    keepAlive: 10_000,
    connectionParams: () => {
      const classToDoToken = getStudentToken();
      const studentUserToken = getStudentUserToken();
      const token = getToken();

      if (!token && !classToDoToken && !studentUserToken) {
        setWsConnectionStatus(false);
        // this is caught by the SubscriptionClient and will trigger connection_error
        throw new Error('No auth token available on client');
      }

      return {
        Headers: {
          Authorization: `Bearer ${studentUserToken ||
            classToDoToken ||
            token}`,
        },
      };
    },
  });
}

export const wsClient = createWsClient();
