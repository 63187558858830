import queryString from 'query-string';

import {
  PUBLIC_UNIT_BASE,
  PUBLIC_TOPIC_BASE,
  PUBLIC_HOME,
} from 'global/public-routes.constants';
import { PRIVATE_CLASS_TO_DO } from 'global/private-routes.constants';
import { isUserClassHomeUrl } from 'approot/shared/logic/is-user-class-home-url';
import { setGlobalModal } from './global-modals.apollo';
import { isKeepOpenOnLoadModal } from 'approot/shared/modal/modal.utils';
import { ModalKey } from 'approot/shared/modal/modal.constants';
import { setSigninRedirectionPath } from 'approot/shared/signin/signin.utils';

const PAGE_WHITELIST = [
  `${PUBLIC_TOPIC_BASE}/`,
  `${PUBLIC_UNIT_BASE}/`,
  PRIVATE_CLASS_TO_DO,
];

function isLessonPath(path: string) {
  return /^.*\/?lesson\/[^/]+\/?$/.test(path);
}

function isAssessmentPath(path: string) {
  return /^.*\/?assessment\/[^/]+\/?$/.test(path);
}

export function isOnWhitelistedPage(pathname: string) {
  return (
    pathname === PUBLIC_HOME ||
    isUserClassHomeUrl(pathname) ||
    PAGE_WHITELIST.some(page => pathname.includes(page)) ||
    isLessonPath(pathname) ||
    isAssessmentPath(pathname)
  );
}

export function openAccessDeniedModal() {
  setGlobalModal(ModalKey.DENIED);
}

export function openAdminModal() {
  setGlobalModal(ModalKey.ADMIN);
}

export function openInactiveAccountModal() {
  setGlobalModal(ModalKey.INACTIVE_ACCOUNT);
}

export function openJoinModal() {
  setGlobalModal(ModalKey.JOIN);
}

export function openSignInModal() {
  setSigninRedirectionPath();
  setGlobalModal(ModalKey.SIGNIN);
}

export const openChooseUserJoinModal = () => {
  setGlobalModal(ModalKey.CHOOSE_USER_JOIN);
};

export const openChooseUserSignInModal = () => {
  setSigninRedirectionPath();
  setGlobalModal(ModalKey.CHOOSE_USER_SIGNIN);
};

export const loadModalFromQuery = () => {
  const query = queryString.parse(window.location.search);
  const modalKey = query.modal;

  if (modalKey && isKeepOpenOnLoadModal(modalKey)) {
    setGlobalModal(modalKey);
  }
};
