import { getClassToDoOrStudentClassToDoId } from 'approot/student-class-to-do/student-class-to-do.utils';
import { getRegionalisedLink } from 'approot/shared/logic/get-regionalised-link';
import { isStaleSessionModalOpenVar } from 'lib/session-stale-monitor.apollo';
import { timeoutSeconds } from 'lib/session-stale-monitor.apollo';
import { studentSignOut } from 'approot/shared/signin/signin.utils';
import { PUBLIC_STUDENTS } from 'global/public-routes.constants';
import { PRIVATE_SIGNIN_REFRESH_SESSION } from 'global/private-routes.constants';

export const SESSION_TIMEOUT_IN_MILLISECONDS = timeoutSeconds * 1000;

export const INTERVAL_IN_MILLISECONDS = 10000;

export function isSessionStale(
  timerStartedAt: number,
  isStudentSignedIn: boolean
) {
  const timeElapsed = Date.now() - timerStartedAt;
  if (
    timeElapsed >
    SESSION_TIMEOUT_IN_MILLISECONDS + INTERVAL_IN_MILLISECONDS
  ) {
    isStaleSessionModalOpenVar(false);
    if (isStudentSignedIn) {
      const classId = getClassToDoOrStudentClassToDoId();
      studentSignOut(classId);
      window.location.replace(getRegionalisedLink(PUBLIC_STUDENTS));
    } else {
      window.location.replace(
        `${getRegionalisedLink(PRIVATE_SIGNIN_REFRESH_SESSION)}?redirect=${
          window.location.pathname
        }${window.location.search}`
      );
    }
  }
}
