import { FetchResult } from '@apollo/client';

import {
  clearAnalyticsDataLayer,
  updateAnalyticsDataLayer,
} from 'approot/shared/analytics/data-layer';
import {
  E_CLICKS_LOGGED_IN,
  pushEClicksDataEvent,
} from 'approot/shared/data-tracker/e-clicks-data-event';
import { pushDataTrackerEvent } from 'approot/shared/data-tracker/data-tracker-push';
import { DataTrackerEventData } from 'approot/shared/data-tracker/data-tracker.constants';
import {
  IS_SIGNED_IN,
  SIGN_IN_REDIRECTION_PATH,
  SKIP_CHOOSE_CLASS_REDIRECTION_PATH,
  GET_USER,
} from 'approot/shared/user/user-data.graphql';
import { UserFragment } from 'approot/shared/user/__generated__/UserFragment';
import { isAccountInactive } from 'approot/shared/user/user-data';
import { setCurrentClass } from 'approot/classes/classes.apollo';
import { openInactiveAccountModal } from 'approot/global-notifications/global-notification.utils';
import {
  EVENT_ACTION_SIGN_IN,
  EVENT_ACTION_SIGN_OUT,
  EVENT_CATEGORY_AUTHENTICATION,
  EVENT_TARGET_TYPE_STUDENT,
  EVENT_TARGET_TYPE_TEACHER,
} from 'approot/shared/data-tracker/data-events.constants';
import {
  setUserData,
  clearUserData,
  updateAlreadySignedInOnce,
  getUserId,
  getToken,
} from 'approot/shared/api/auth/auth';
import {
  setStudentUserData,
  clearStudentUserData,
  getStudentUserData,
} from 'approot/shared/api/auth/student-user-auth';
import {
  clearStudentSession,
  getStudentUserToken,
} from 'approot/student-class-to-do/student-class-to-do.utils';
import { StorageUserData } from 'approot/shared/api/auth/auth.constants';
import { PRIVATE_ACCOUNT_REACTIVATE } from 'global/private-routes.constants';
import { client as apolloClient } from '../../../apollo';
import { RememberType } from './signin.constants';
import { StudentFragment } from 'approot/students/__generated__/StudentFragment';
import {
  endIntercomSession,
  startAuthenticatedIntercomSession,
  startUnauthenticatedIntercomSession,
} from 'lib/intercom/intercom';
import { wsClient } from 'lib/ws/ws-client';
import { SignOut } from 'approot/shared/signin/__generated__/SignOut';
import { SIGN_OUT } from 'approot/shared/signin/signin.graphql';
import { GRAPHQL_REQUEST_TRACE_ID } from 'global/constants';
import { generateTraceId } from 'approot/shared/api/auth/generate-trace-id';
import { logException } from 'approot/shared/debug';
import { isSignedInVar } from 'approot/shared/user/user-data.apollo';
import { isStudentSignedInVar } from 'approot/shared/user/user-data.apollo';

export function handleSignInData(
  remember: boolean,
  token: string,
  user: UserFragment,
  sessionAuthProvider?: string | null
) {
  const rememberType = remember
    ? RememberType.LocalStorage
    : RememberType.SessionStorage;
  const userId = user.id;

  const userWithSessionAuth = {
    ...user,
    sessionAuthProvider: sessionAuthProvider || null,
  };

  const data: StorageUserData = {
    token,
    user: userWithSessionAuth,
  };

  // add user data to storage
  if (
    rememberType === RememberType.LocalStorage ||
    rememberType === RememberType.SessionStorage
  ) {
    setUserData(data, rememberType);
  }

  apolloClient.getClient().writeQuery({
    query: GET_USER,
    data: {
      user: userWithSessionAuth,
    },
  });

  updateSignInStateInCache({
    isSignedIn: true,
    isAlreadySignedInOnce: true,
    isStudentSignedIn: false,
  });

  updateAlreadySignedInOnce();
  updateAnalyticsDataLayer(user);

  if (userId) {
    if (
      isAccountInactive(user.accountStatus) &&
      user.hasStrongPassword &&
      window.location.pathname !== PRIVATE_ACCOUNT_REACTIVATE
    ) {
      openInactiveAccountModal();
    }
  }

  startAuthenticatedIntercomSession({
    id: user.id,
    email: user.email,
    hash: user.intercomHash,
    schoolId: user.school.id,
    schoolName: user.school.name,
    accountType: user.accountType,
  });
}

export function handleStudentUserSignin(
  token: string,
  studentUser: StudentFragment
) {
  const existingStudentUserData = getStudentUserData();

  // Check if the same student user is refreshing the session.
  // This ensures classId and classToDoId from session storage are preserved.
  const isSameStudentUser =
    existingStudentUserData?.studentUser.email === studentUser.email;

  setStudentUserData({
    ...(isSameStudentUser ? existingStudentUserData : {}),
    token,
    studentUser,
  });

  updateSignInStateInCache({
    isSignedIn: false,
    isAlreadySignedInOnce: true,
    isStudentSignedIn: true,
  });
}

function signOutAtServer(
  token: string | undefined
): Promise<void | FetchResult<SignOut>> {
  const client = apolloClient.getClient();

  if (!token) return Promise.resolve();

  return client
    .mutate<SignOut, null>({
      mutation: SIGN_OUT,
      fetchPolicy: 'network-only',
      context: {
        headers: {
          authorization: `Bearer ${token}`,
          [GRAPHQL_REQUEST_TRACE_ID]: generateTraceId(),
        },
      },
    })
    .catch(res => {
      logException({
        sourceFile: 'signin.utils.tsx',
        message: `There was an issue signing out at the server. Response status was: ${res.networkStatus}, ${res}`,
        statusCode: res.networkStatus,
      });
    });
}

export function signOut() {
  pushDataTrackerEvent(EVENT_CATEGORY_AUTHENTICATION, EVENT_ACTION_SIGN_OUT, {
    target_type: EVENT_TARGET_TYPE_TEACHER,
    target_identifier: getUserId(),
  });

  const token = getToken();

  clearUserData();

  // sessionStorage can contain data queried by a logged in user
  // if location.state is utilised i.e. in the add teacher purchase flow

  clearAnalyticsDataLayer();

  // if there is an active websocket client close it
  if (wsClient) {
    wsClient.dispose();
  }

  setCurrentClass(undefined);

  // this needs to be done first so resetStore re-queries
  // without authorization headers
  updateSignInStateInCache({
    isSignedIn: false,
    isAlreadySignedInOnce: true,
    isStudentSignedIn: false,
  });

  endIntercomSession();
  startUnauthenticatedIntercomSession();

  apolloClient
    ?.getClient()
    ?.clearStore()
    .then(() => {
      signOutAtServer(token);
    });
}

export function studentSignOut(
  classId: string | undefined,
  callback?: () => void
) {
  pushDataTrackerEvent(EVENT_CATEGORY_AUTHENTICATION, EVENT_ACTION_SIGN_OUT, {
    target_type: EVENT_TARGET_TYPE_STUDENT,
    target_identifier: classId,
  });

  const token = getStudentUserToken();

  clearAnalyticsDataLayer();

  // if there is an active websocket client close it
  if (wsClient) {
    wsClient.dispose();
  }

  // this needs to be done first so resetStore re-queries
  // without authorization headers
  updateSignInStateInCache({
    isSignedIn: false,
    isAlreadySignedInOnce: true,
    isStudentSignedIn: false,
  });

  clearStudentUserData();
  clearStudentSession();

  apolloClient
    ?.getClient()
    ?.clearStore()
    .then(callback)
    .finally(() => {
      signOutAtServer(token);
    });
}

type ClientSignIn = {
  isSignedIn: boolean;
  isAlreadySignedInOnce: boolean;
  isStudentSignedIn: boolean;
};

export function updateSignInStateInCache({
  isSignedIn,
  isAlreadySignedInOnce,
  isStudentSignedIn = false,
}: ClientSignIn) {
  isSignedInVar(isSignedIn);
  isStudentSignedInVar(isStudentSignedIn);

  apolloClient.getClient().writeQuery({
    query: IS_SIGNED_IN,
    data: {
      isSignedIn,
      isAlreadySignedInOnce,
      isStudentSignedIn,
    },
  });
}

export function setSigninRedirectionPath(redirectPath: string = '') {
  apolloClient.getClient().writeQuery({
    query: SIGN_IN_REDIRECTION_PATH,
    data: {
      signInRedirectionPath: redirectPath
        ? redirectPath
        : `${window.location.pathname}${window.location.search}`,
    },
  });
}

export function setSkipChooseClassPath(redirectPath: string = '') {
  apolloClient.getClient().writeQuery({
    query: SKIP_CHOOSE_CLASS_REDIRECTION_PATH,
    data: {
      skipChooseClassRedirectionPath: redirectPath
        ? redirectPath
        : window.location.pathname,
    },
  });
}

export function pushSignInToGtm(eventData: DataTrackerEventData) {
  pushDataTrackerEvent(
    EVENT_CATEGORY_AUTHENTICATION,
    EVENT_ACTION_SIGN_IN,
    eventData
  );
}

export function pushSignInEClicks(userId: number, position: string) {
  pushEClicksDataEvent({
    event: 'login',
    status: E_CLICKS_LOGGED_IN,
    userId,
    position,
  });
}
