import styled, { css } from 'styled-components';

import {
  coolGrey2,
  grey1,
  grey2,
  grey3,
  grey4,
  grey5,
  grey6,
  grey7,
  grey8,
  red7,
} from 'approot/shared/styles/colours.style';
import { rem } from 'approot/shared/style';
import {
  spacing8,
  spacing16,
  spacing40,
  spacing4,
  marginCss,
  MarginProps,
} from 'approot/shared/styles/spacing.style';
import {
  borderRadius16,
  boxShadowBlack12,
  standardFocusState,
} from 'approot/shared/styles/borders.style';
import { skeletonState } from 'approot/shared/styles/transitions.style';
import { SecondaryButton } from 'approot/shared/styles/buttons/buttons';

export const dropdownMenuCommonCss = css`
  ${boxShadowBlack12}
  background-color: ${grey1};
  border-radius: ${rem(borderRadius16)};
  border: 1px solid ${grey4};
  display: flex;
  flex-direction: column;
  min-width: ${rem(140)};
  max-width: ${rem(360)};
  max-height: ${rem(420)};
  overflow: auto;
  overflow-x: hidden;
  padding: ${rem(spacing8)};
  width: auto;
  z-index: 1000;
`;

type DropdownMenuProps = {
  menuWidth?: number;
  menuHeight?: number;
};
export const DropdownMenu = styled.div<DropdownMenuProps>`
  ${dropdownMenuCommonCss}
  ${({ menuWidth }) =>
    menuWidth &&
    `
    max-width: ${rem(menuWidth > 360 ? menuWidth : 360)};
    width: ${rem(menuWidth)};
  `}

  ${({ menuHeight = 420 }) =>
    menuHeight &&
    `
    max-height: ${rem(menuHeight)};
  `}
`;

type DropdownProps = {
  isSmall?: boolean;
  hasError?: boolean;
  menuMatchToggleWidth?: boolean;
};
export const Dropdown = styled.div<DropdownProps>`
  max-width: 100%;
  ${({ menuMatchToggleWidth }) =>
    menuMatchToggleWidth &&
    `
    position: relative;
    ${DropdownMenu} {
      width: 100%;
    }
  `}

  ${({ isSmall }) =>
    isSmall &&
    `
    ${DropdownToggleContent} {
      line-height: ${rem(30)};
    }
  `}

  ${({ hasError }) =>
    hasError &&
    `
    ${DropdownToggle} {  
      border: 1px solid ${red7};
    }
  `}
`;

export const DropdownToggleContent = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const DropdownToggleIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: ${rem(40)};
`;

type DropdownToggleProps = {
  isTransparent?: boolean;
  $borderRadius?: number;
  isInline?: boolean;
  $isIcon?: boolean;
};
export const DropdownToggle = styled.button.attrs<
  DropdownToggleProps & MarginProps
>({
  type: 'button',
  isTransparent: false,
})`
  ${() => marginCss()}

  border-radius: ${({ $borderRadius = 80 }) => rem($borderRadius)};
  display: flex;
  align-items: center;
  position: relative;
  text-align: left;

  ${({ isInline }) => !isInline && `width: 100%;`}

  ${standardFocusState}

  &:hover {
    cursor: pointer;
  }

  ${({ isTransparent = true }) =>
    !isTransparent
      ? `
    background-color: ${grey1};
    border: 1px solid ${grey5};
    padding: 0 ${rem(spacing40)} 0 ${rem(spacing16)};
    line-height: ${rem(38)};

    &:hover {
      background-color: ${grey2};
      border-color: ${grey7};
      cursor: pointer;
    }
  `
      : `
    background: transparent;
    border: none;
    padding: 0;
  `}

  ${({ $isIcon }) =>
    $isIcon &&
    `
    height: ${rem(40)};
    padding-left: ${rem(spacing16)};
  `}

  &:disabled {
    color: ${grey6};
    border: 1px solid ${grey5};
    background-color: ${grey3};
    cursor: not-allowed;

    ${DropdownToggleIcon} svg {
      stroke: ${grey6};
      fill: ${grey3};
    }
  }

  ${DropdownToggleIcon} svg {
    fill: ${grey8};
    stroke: ${grey8};
  }
`;

export const DropdownSkeleton = styled(DropdownToggle).attrs({
  as: 'div',
})`
  ${skeletonState}
  background-color: ${coolGrey2};
  border-color: ${coolGrey2};
  height: ${rem(40)};
`;

export const DropdownToggleSecondaryMore = styled(SecondaryButton)`
  display: flex;
  align-items: center;
  svg {
    margin-left: ${rem(spacing4)};
  }
`;
