import { ReactNode, useEffect, lazy, Suspense } from 'react';
import { useReactiveVar } from '@apollo/client';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { useLoggingQuery } from 'lib/apollo/apollo-hooks';
import { globalRefreshNotificationVar } from 'approot/global-notifications/global-notification.apollo';
import useUserData from 'approot/shared/user/user-data';
import { ModalKey } from 'approot/shared/modal/modal.constants';
import {
  isOnWhitelistedPage,
  loadModalFromQuery,
} from './global-notification.utils';
import { GET_USER_NOTIFICATIONS } from './global-notifications.graphql';
import { GetUserNotifications } from './__generated__/GetUserNotifications';
import { useGlobalModal } from './global-modals.hooks';
import { globalClassesNotificationVar } from './classes/global-classes-notification.apollo';
import { isStaleSessionModalOpenVar } from 'lib/session-stale-monitor.apollo';

type Props = {
  children: ReactNode;
};

const JoinModal = lazy(() => import('approot/join/join-modal/join-modal'));
const JoinChooseUserModal = lazy(() =>
  import('approot/shared/choose-user-modal/choose-user-join-modal')
);
const GlobalRefresh = lazy(() => import('./refresh/global-refresh'));
const InactiveAccountModal = lazy(() =>
  import('approot/shared/inactive-account-modal/inactive-account-modal')
);
const AccessDeniedModal = lazy(() =>
  import('approot/shared/access-denied-modal/access-denied-modal')
);
const AdminModal = lazy(() =>
  import('approot/account/admin-modal/admin-modal')
);
const SigninModal = lazy(() =>
  import('approot/shared/signin/signin-modal/signin-modal')
);
const SigninChooseUserModal = lazy(() =>
  import('approot/shared/choose-user-modal/choose-user-signin-modal')
);
const ContentNotificationModal = lazy(() =>
  import('./content-notification/content-notification-modal')
);
const GlobalClassesNotification = lazy(() =>
  import('./classes/global-classes-notification')
);
const SessionTimeoutModal = lazy(() =>
  import('approot/shared/session-timeout-modal/session-timeout-modal')
);

const GlobalNotifications = ({ children }: Props) => {
  const flags = useFlags();
  const { isSignedIn } = useUserData();

  const { globalModal, closeGlobalModal } = useGlobalModal();
  const refreshNotificationVisible = useReactiveVar(
    globalRefreshNotificationVar
  );
  const classesNotificationError = useReactiveVar(globalClassesNotificationVar);
  const isStaleSessionModalOpen = useReactiveVar(isStaleSessionModalOpenVar);
  const { data } = useLoggingQuery<GetUserNotifications, null>(
    GET_USER_NOTIFICATIONS,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      skip: !isSignedIn || !isOnWhitelistedPage(window.location.pathname),
    }
  );

  useEffect(() => {
    loadModalFromQuery();
  }, []);

  const user = data && data.user;
  const contentReleaseNotifications = user && user.contentReleaseNotifications;

  return (
    <>
      <Suspense fallback={<div />}>
        {contentReleaseNotifications &&
          contentReleaseNotifications.length > 0 && (
            <ContentNotificationModal
              release={contentReleaseNotifications || []}
            />
          )}
      </Suspense>

      {isSignedIn ? (
        <>
          {globalModal === ModalKey.DENIED && (
            <Suspense fallback={<div />}>
              <AccessDeniedModal isVisible closeModal={closeGlobalModal} />
            </Suspense>
          )}
          {globalModal === ModalKey.INACTIVE_ACCOUNT && (
            <Suspense fallback={<div />}>
              <InactiveAccountModal isVisible closeModal={closeGlobalModal} />
            </Suspense>
          )}
          {globalModal === ModalKey.ADMIN && (
            <Suspense fallback={<div />}>
              <AdminModal isVisible closeModal={closeGlobalModal} />
            </Suspense>
          )}
        </>
      ) : (
        <>
          {globalModal === ModalKey.JOIN && (
            <Suspense fallback={<div />}>
              <JoinModal isVisible closeModal={closeGlobalModal} />
            </Suspense>
          )}
          {(globalModal === ModalKey.SIGNIN ||
            globalModal === ModalKey.STUDENT_SIGNIN) && (
            <Suspense fallback={<div />}>
              <SigninModal isVisible closeModal={closeGlobalModal} />
            </Suspense>
          )}
          {globalModal === ModalKey.CHOOSE_USER_JOIN && (
            <Suspense fallback={<div />}>
              <JoinChooseUserModal isVisible closeModal={closeGlobalModal} />
            </Suspense>
          )}
          {globalModal === ModalKey.CHOOSE_USER_SIGNIN && (
            <Suspense fallback={<div />}>
              <SigninChooseUserModal isVisible closeModal={closeGlobalModal} />
            </Suspense>
          )}
        </>
      )}
      {isStaleSessionModalOpen && (
        <Suspense fallback={<div />}>
          <SessionTimeoutModal />
        </Suspense>
      )}
      <Suspense fallback={<div />}>
        {flags.featureRefreshBanner && refreshNotificationVisible && (
          <GlobalRefresh />
        )}
      </Suspense>
      {classesNotificationError && (
        <Suspense fallback={<div />}>
          <GlobalClassesNotification error={classesNotificationError} />
        </Suspense>
      )}
      {children}
    </>
  );
};

export default GlobalNotifications;
