import { gql } from '@apollo/client';

export const REFRESH_SESSION = gql`
  mutation RefreshSession($input: RefreshSessionInput!) {
    refreshSession(input: $input)
  }
`;

export const EXTEND_SESSION = gql`
  query ExtendSession {
    extendSession
  }
`;
