import LazyRoute from 'approot/shared/routes/lazy-route';
import { PRIVATE_CLASSES_PARAM } from 'global/private-routes.constants';
import { PUBLIC_SUBJECT } from 'global/public-routes.constants';
import { RouteConfig } from 'lib/router/router.constants';
import { SUBJECT_ABOUT } from './subject-browser.constants';

export const subjectBrowserRoutes: RouteConfig[] = [
  {
    path: [
      `${PUBLIC_SUBJECT}/*`,
      `${PRIVATE_CLASSES_PARAM}${PUBLIC_SUBJECT}/*`,
    ],
    component: LazyRoute(() => import('./subject-browser')),
    childRoutes: [
      {
        path: '',
        component: LazyRoute(() => import('./subject-browser-content')),
      },
      {
        path: SUBJECT_ABOUT,
        component: LazyRoute(() => import('./subject-browser-about')),
      },
      {
        path: '*',
        component: LazyRoute(() => import('./subject-browser-not-found')),
      },
    ],
  },
];
