import styled, { css } from 'styled-components';

import { rem } from 'approot/shared/style';
import { grey1, grey9 } from 'approot/shared/styles/colours.style';
import {
  fontSize14,
  fontSize14LineHeight,
  fontSize16,
  fontSize16LineHeight,
  fontWeightMedium,
  fontWeightNormal,
} from 'approot/shared/styles/typography.style';
import { borderRadius8 } from 'approot/shared/styles/borders.style';
import { spacing8, spacing16 } from 'approot/shared/styles/spacing.style';

export const TOOLTIP_HEIGHT = 36;
export const TOOLTIP_MARGIN = 8;

export const TOOLTIP_TAIL_OFFSET = 8; // adjust to move the tail further from the edge
export const TOOLTIP_TAIL_WIDTH = 12;

type position = 'top' | 'bottom';
type alignment = 'left' | 'right' | 'center';

const generateTooltipTail = (position: position, alignment: alignment) => {
  return css`
    content: '';
    position: absolute;
    left: ${
      alignment === 'left'
        ? rem(TOOLTIP_TAIL_OFFSET)
        : alignment === 'right'
        ? `calc(100% - ${rem(TOOLTIP_TAIL_OFFSET + TOOLTIP_TAIL_WIDTH)})`
        : '50%'
    };
    transform: ${alignment === 'center' ? 'translateX(-50%)' : 'none'};
    ${position === 'top' &&
      `
      bottom: 100%;
      border-bottom: ${rem(TOOLTIP_TAIL_WIDTH / 2)} solid ${grey9};
    `}
    ${position === 'bottom' &&
      `
      top: 100%;
      border-top: ${rem(TOOLTIP_TAIL_WIDTH / 2)} solid ${grey9};
    `}
    border-left: ${rem(TOOLTIP_TAIL_WIDTH / 2)} solid transparent;
    border-right: ${rem(TOOLTIP_TAIL_WIDTH / 2)} solid transparent;
  `;
};

export const TooltipStyled = styled.div`
  position: absolute;
  color: ${grey1};
  background-color: ${grey9};
  box-shadow: 0px 4px 8px -4px rgba(22, 34, 51, 0.08),
    0px 16px 24px rgba(22, 34, 51, 0.08);
  border-radius: ${rem(borderRadius8)};
  padding: ${rem(spacing8)} ${rem(spacing16)};
  top: -${TOOLTIP_HEIGHT + TOOLTIP_MARGIN}px;
  z-index: 200;

  min-height: ${TOOLTIP_HEIGHT}px;
  max-width: ${rem(360)};

  &[data-tail-position='bottom-center']::after {
    ${generateTooltipTail('bottom', 'center')}
  }

  &[data-tail-position='bottom-left']::after {
    ${generateTooltipTail('bottom', 'left')}
  }

  &[data-tail-position='bottom-right']::after {
    ${generateTooltipTail('bottom', 'right')}
  }

  &[data-tail-position='top-center']::after {
    ${generateTooltipTail('top', 'center')}
  }

  &[data-tail-position='top-left']::after {
    ${generateTooltipTail('top', 'left')}
  }

  &[data-tail-position='top-right']::after {
    ${generateTooltipTail('top', 'right')}
  }

  div {
    display: flex;
    justify-content: space-between;
    font-size: ${rem(fontSize16)};
    font-weight: ${fontWeightMedium};
    line-height: ${rem(fontSize16LineHeight)};

    span {
      font-weight: ${fontWeightNormal};
      margin-left: ${rem(spacing16)};
    }
  }

  p {
    font-size: ${rem(fontSize14)};
    font-weight: ${fontWeightNormal};
    line-height: ${rem(fontSize14LineHeight)};
    margin: 0;
  }
`;

export const TooltipWrapperStyled = styled.div`
  display: inline-block;
  position: relative;
`;

export const TooltipIconWrapperStyled = styled.div`
  display: flex;
  gap: ${rem(spacing8)};

  svg {
    path {
      stroke: ${grey1};
    }
  }
`;
