import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';

import {
  isStaleSessionModalOpenVar,
  sessionTimerVar,
} from 'lib/session-stale-monitor.apollo';
import {
  BASE_ACTIVITIES_PRESENT,
  PRIVATE_SIGNIN_REFRESH_SESSION,
  PRIVATE_SIGNIN_REFRESH_STUDENT_SESSION,
} from 'global/private-routes.constants';
import { getStudentData } from 'approot/student-class-to-do/student-class-to-do.utils';
import {
  isSignedInVar,
  isStudentSignedInVar,
} from 'approot/shared/user/user-data.apollo';
import { isSessionStale } from 'lib/session-stale-monitor.utils';
import { INTERVAL_IN_MILLISECONDS } from 'lib/session-stale-monitor.utils';
import { useExtendSession } from 'approot/shared/signin/signin-refresh-session/use-extend-session';

const FIVE_MINUTES_IN_SECONDS = 5 * 60;

export const SessionStaleMonitor = () => {
  const location = useLocation();
  const sessionTimer = useReactiveVar(sessionTimerVar);
  const isModalOpen = useReactiveVar(isStaleSessionModalOpenVar);
  const isSignedIn = useReactiveVar(isSignedInVar);
  const isStudentSignedIn = useReactiveVar(isStudentSignedInVar);
  const isClassToDoStudent = getStudentData().token;

  // Timer should only run if the user is a signed in or an authenticated student
  // and the user is not on the refresh session page
  const shouldTimerBeRunning = useMemo(
    () =>
      (isSignedIn || (isStudentSignedIn && !isClassToDoStudent)) &&
      !location.pathname.includes(PRIVATE_SIGNIN_REFRESH_SESSION) &&
      !location.pathname.includes(PRIVATE_SIGNIN_REFRESH_STUDENT_SESSION),
    [isSignedIn, isStudentSignedIn, location.pathname, isClassToDoStudent]
  );

  const { extendSession } = useExtendSession();

  useEffect(() => {
    if (!shouldTimerBeRunning) {
      return;
    }

    // Every 10 seconds set the session timer and check if the session timer is 5 minutes
    const interval = setInterval(() => {
      const newSessionTimer = sessionTimer.timeoutSeconds - 10;
      sessionTimerVar({
        timeoutSeconds: newSessionTimer,
        timerStartedAt: sessionTimer.timerStartedAt,
      });

      if (newSessionTimer <= FIVE_MINUTES_IN_SECONDS && !isModalOpen) {
        // if something is in full screen mode in presentation
        // do not show the modal and just extend the session
        if (
          document.fullscreenElement &&
          location.pathname.includes(BASE_ACTIVITIES_PRESENT)
        ) {
          extendSession();
        } else {
          isStaleSessionModalOpenVar(true);
        }
      }
    }, INTERVAL_IN_MILLISECONDS);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionTimer, shouldTimerBeRunning, location, extendSession]);

  // Compare system dates to check if the session is stale as if the window has been minimised
  // the JS execution will pause and the timer will not update
  useEffect(() => {
    if (!shouldTimerBeRunning) {
      return;
    }

    const interval = setInterval(() => {
      isSessionStale(sessionTimer.timerStartedAt, isStudentSignedIn);
    }, INTERVAL_IN_MILLISECONDS);

    return () => clearInterval(interval);
  }, [isStudentSignedIn, shouldTimerBeRunning, sessionTimer.timerStartedAt]);

  return null;
};
