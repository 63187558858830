import { ApolloClient, ApolloLink, HttpLink, split } from '@apollo/client';
import { InMemoryCache } from '@apollo/client/cache';
import { onError, ErrorResponse } from '@apollo/client/link/error';
import { ServerError } from 'apollo-link-http-common';
import { RetryLink } from '@apollo/client/link/retry';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from 'apollo-utilities';
import { Location } from 'react-router-dom';

import {
  GRAPHQL_REQUEST_TRACE_ID,
  IGNORED_STATUS_CODES,
} from 'global/constants';
import { PUBLIC_SEARCH } from 'global/public-routes.constants';
import { SERVERLESS_API_BASE } from 'webclient.constants';
import {
  IS_SIGNED_IN,
  GET_USER,
  userAuthTypeDefs,
  SKIP_CHOOSE_CLASS_REDIRECTION_PATH,
} from 'approot/shared/user/user-data.graphql';
import { updateSignInStateInCache } from 'approot/shared/signin/signin.utils';
import {
  checkForCurrentClassError,
  checkForInvalidClassToDoToken,
  checkIfSessionIsStale,
  getClientStateDefaults,
} from 'apollo.utils';
import generatedFragmentMatcher from '__generated__/fragmentMatcher.json';
import { paginationTypeDefs } from 'approot/content-browser/content-browser.graphql';
import { contentBrowserMerge } from 'approot/content-browser/content-browser.utils';
import { ContentBrowserVariables } from 'approot/content-browser/__generated__/ContentBrowser';
import { searchParamsTypeDefs } from 'approot/search/search.graphql';
import {
  getSearchParamsFromUrl,
  getTrackerParamsFromUrl,
  updateSearchParamsInCache,
} from 'approot/search/search.utils';
import {
  localUserClassTypeDefs,
  getCurrentClass,
} from 'approot/classes/classes.apollo';
import {
  localContentFiltersTypeDefs,
  getContentFilters,
} from 'approot/shared/filters/filters.apollo';
import { sessionStorageWrapper } from 'approot/shared/storage';
import { SKIP_PATH_DATA_KEY } from 'approot/shared/api/auth/auth.constants';
import {
  getStimulusBrowserFilters,
  localStimulusBrowserFiltersTypeDefs,
} from 'approot/stimulus-browser/stimulus-browser.apollo';
import { stimulusBrowserMerge } from 'approot/stimulus-browser/stimulus-subject-browser.utils';
import { SearchStimulusResourcesVariables } from 'approot/shared/stimulus/__generated__/SearchStimulusResources';
import { globalRefreshNotificationVar } from 'approot/global-notifications/global-notification.apollo';
import { GET_STUDENT } from 'approot/students/students.graphql';
import { GetStudent } from 'approot/students/__generated__/GetStudent';
import {
  interactiveLessonQueryTypePolicies,
  interactiveLessonTypePolicies,
  override,
} from 'apollo-type-policies';
import { globalErrorResponseVar } from 'startup.apollo';
import { logException } from 'approot/shared/debug';
import { wsClient } from 'lib/ws/ws-client';

const versionCheckLink = new ApolloLink((operation, forward) => {
  return forward(operation).map(data => {
    const requestContext = operation.getContext();

    // no response exists on WS operations
    if (requestContext.response) {
      const latestAppApiVersion = requestContext.response.headers.get(
        'x-latest-api-version'
      );
      const webAppApiVersion = process.env.REACT_APP_API_VERSION;
      const refreshNotificationVisible = globalRefreshNotificationVar();
      if (
        latestAppApiVersion !== null &&
        latestAppApiVersion !== webAppApiVersion &&
        !refreshNotificationVisible
      ) {
        globalRefreshNotificationVar(true);
      }
    }

    return data;
  });
});

const localCache = new InMemoryCache({
  possibleTypes: generatedFragmentMatcher.possibleTypes,
  typePolicies: {
    Permission: {
      merge: true,
    },
    Billing: {
      merge: true,
    },
    Lesson: {
      fields: {
        data: {
          merge(existing, incoming) {
            return {
              ...(existing || {}),
              ...(incoming || {}),
            };
          },
        },
        outcomes: {
          merge: override,
        },
        stimulusResources: {
          merge: override,
        },
        bigIdeas: {
          merge: override,
        },
        textualConcepts: {
          merge: override,
        },
        teachingAndLearningSequence: {
          merge: override,
        },
        materials: {
          merge: override,
        },
        squareImage: {
          merge: override,
        },
      },
    },
    ContentItem: {
      fields: {
        data: {
          merge: override,
        },
      },
    },
    Assessment: {
      fields: {
        outcomes: {
          merge: override,
        },
        data: {
          merge(existing, incoming) {
            return {
              ...(existing || {}),
              ...(incoming || {}),
            };
          },
        },
        stimulusResources: {
          merge: override,
        },
        squareImage: {
          merge: override,
        },
      },
    },
    Unit: {
      fields: {
        data: {
          merge(existing, incoming) {
            return {
              ...(existing || {}),
              ...(incoming || {}),
            };
          },
        },
        assessments: {
          merge: override,
        },
        lessons: {
          merge: override,
        },
        unitPlannersInfo: {
          merge: override,
        },
        strands: {
          merge: override,
        },
        subStrands: {
          merge: override,
        },
      },
    },
    Planner: {
      fields: {
        plannerTerms: {
          merge: override,
        },
      },
    },
    PlannerPerTerm: {
      fields: {
        plannerItemGroupsByUnits: {
          merge: override,
        },
      },
    },
    PlannerItemGroupsByUnit: {
      // this grouping does not return an id
      keyFields: ['plannerItemGroupId'],
    },
    CompletionData: {
      keyFields: ['contentId', 'contentType'],
    },
    School: {
      fields: {
        schoolTeachers: {
          merge: override,
        },
        personalTeachers: {
          merge: override,
        },
      },
    },
    User: {
      fields: {
        sessionAuthProvider: {
          read(provider) {
            return provider || null;
          },
        },
        planners: {
          merge: override,
        },
        userClasses: {
          merge: override,
        },
      },
    },
    UserClass: {
      fields: {
        years: {
          merge: override,
        },
        subjects: {
          merge: override,
        },
        classTeachers: {
          merge: override,
        },
      },
    },
    PlannerAddableUnit: {
      keyFields: ['unitId'],
    },
    StaticTeachingLearningSequenceItem: {
      fields: {
        data: {
          merge: override,
        },
      },
    },
    DictionaryBookItem: {
      fields: {
        aiDefinitions: {
          merge: override,
        },
      },
    },
    ...interactiveLessonTypePolicies,
    Query: {
      fields: {
        contentBrowser: {
          keyArgs: false,
          merge(existing, incoming, { cache, variables }) {
            return contentBrowserMerge(
              existing,
              incoming,
              cache,
              variables as ContentBrowserVariables
            );
          },
        },
        searchStimulusResources: {
          keyArgs: false,
          merge(existing, incoming, { variables }) {
            return stimulusBrowserMerge(
              existing,
              incoming,
              variables as SearchStimulusResourcesVariables
            );
          },
        },
        bookmarks: {
          merge: override,
        },
        currentUserClass: {
          read() {
            return getCurrentClass();
          },
        },
        contentFilters: {
          read() {
            return getContentFilters();
          },
        },
        stimulusBrowserFilters: {
          read() {
            return getStimulusBrowserFilters();
          },
        },
        getClasses: {
          merge: override,
        },
        getAnnotations: {
          merge: override,
        },
        getUnitAnnotationsOverview: {
          merge: override,
        },
        getClassStudents: {
          merge: override,
        },
        ...interactiveLessonQueryTypePolicies,
      },
    },
  },
});

const clientState = getClientStateDefaults();

localCache.writeQuery({
  query: IS_SIGNED_IN,
  data: clientState,
});

try {
  // hydrate Apollo with the user & student
  if (clientState.user) {
    localCache.writeQuery({
      query: GET_USER,
      data: {
        user: clientState.user,
      },
    });
  }

  if (clientState.student) {
    localCache.writeQuery<GetStudent, null>({
      query: GET_STUDENT,
      data: {
        getStudent: clientState.student,
      },
    });
  }

  const skipPath = sessionStorageWrapper.getItem(SKIP_PATH_DATA_KEY);

  if (skipPath) {
    localCache.writeQuery({
      query: SKIP_CHOOSE_CLASS_REDIRECTION_PATH,
      data: {
        skipChooseClassRedirectionPath: skipPath,
      },
    });

    sessionStorageWrapper.removeItem(SKIP_PATH_DATA_KEY);
  }
} catch (e) {
  // will catch when user data in browser storage does not match user fragment fields
}

const RETRY_OPERATIONS = [
  'GetTopicDetails',
  'GetUnitDetails',
  'GetLessonById',
  'GetAssessmentById',
];

const logErrorToDataTracker = (error: ErrorResponse) => {
  const networkError = error.networkError;

  if (networkError) {
    const statusCode = (networkError as ServerError).statusCode;

    if (statusCode && !IGNORED_STATUS_CODES.includes(statusCode)) {
      const headers = error.operation.getContext().headers;
      const uniqueRequestId = headers[GRAPHQL_REQUEST_TRACE_ID];

      logException({
        sourceFile: 'apollo.tsx',
        message: `Server endpoint returned a status code of ${statusCode}.${
          error ? ` Error: ${networkError.message}` : ''
        }. Unique request id: ${uniqueRequestId}`,
        statusCode,
      });
    }
  }
};

export class InqApolloClient {
  private client: ApolloClient<any>;
  private location: Location | undefined = undefined;

  constructor(cache: InMemoryCache) {
    this.client = new ApolloClient({
      link: ApolloLink.from([
        versionCheckLink,
        onError(error => {
          globalErrorResponseVar(error);
          checkIfSessionIsStale(error);
          checkForCurrentClassError(error);
          checkForInvalidClassToDoToken(error);
          logErrorToDataTracker(error);
        }),
        new RetryLink({
          delay: {
            initial: 300,
            jitter: true,
          },
          attempts: {
            max: 3,
            retryIf: (error, _operation) => {
              if (
                error.statusCode === 404 ||
                !RETRY_OPERATIONS.includes(_operation.operationName)
              ) {
                return false;
              }
              return !!error;
            },
          },
        }),
        split(
          // split based on operation type
          ({ query }) => {
            const definition = getMainDefinition(query);
            return (
              definition.kind === 'OperationDefinition' &&
              definition.operation === 'subscription'
            );
          },
          new GraphQLWsLink(wsClient),
          new HttpLink({
            uri: `${SERVERLESS_API_BASE}/graphql`,
            credentials: 'same-origin',
          })
        ),
      ]),
      cache: cache,
      resolvers: {}, // this tells apollo to look locally for client state variables
      typeDefs: [
        userAuthTypeDefs,
        paginationTypeDefs,
        searchParamsTypeDefs,
        localUserClassTypeDefs,
        localContentFiltersTypeDefs,
        localStimulusBrowserFiltersTypeDefs,
      ],
    });

    this.client.onResetStore(() => {
      return Promise.resolve().then(() => {
        updateSignInStateInCache({
          isSignedIn: false,
          isAlreadySignedInOnce: true,
          isStudentSignedIn: false,
        });

        if (this.location?.pathname === PUBLIC_SEARCH) {
          const searchParams = getSearchParamsFromUrl(this.location);
          const { isSuggestion } = getTrackerParamsFromUrl();

          updateSearchParamsInCache(this.client, {
            ...searchParams,
            isSuggestion,
          });
        }
      });
    });
  }

  getClient() {
    return this.client;
  }

  // this will be set from he startup.tsx component
  setLocation(location: Location) {
    this.location = location;
  }
}

export const client = new InqApolloClient(localCache);
