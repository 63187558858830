import { DOCUMENT, EBOOK_PDF, IMAGE, LINK, WEB_TOOL } from 'global/constants';
import {
  InquisitiveRole,
  InquisitiveUserPosition,
} from 'global/graphql/scalars.type';

export const DATA_TRACKER_EVENT = 'event';
export const DATA_TRACKER_EVENT_CATEGORY = 'event_category';
export const DATA_TRACKER_EVENT_ACTION = 'event_action';
export const DATA_TRACKER_EVENT_DATA = 'event_data';
export const DATA_TRACKER_EVENT_TARGET_TYPE = 'target_type';
export const DATA_TRACKER_EVENT_TARGET_IDENTIFIER = 'target_identifier';
export const DATA_TRACKER_PAGE_PARAMS = 'page_params';
export const DATA_TRACKER_PAGE_PATH = 'page_path';
export const DATA_TRACKER_USER_TYPE = 'user_type';
export const DATA_TRACKER_USER_POSITION = 'user_position';
export const DATA_TRACKER_SESSION_ID = 'session_id';
export const DATA_TRACKER_CLIENT_ID = 'client_id';
export const DATA_TRACKER_USER_ID = 'user_id';
export const DATA_TRACKER_SUBSCRIPTION_ID = 'subscription_id';
export const DATA_TRACKER_CLASS_ID = 'class_id';
export const DATA_TRACKER_CLASS_USER_ID = 'class_user_id';
export const DATA_TRACKER_TYPE_TEACHER = 'teacher';
export const DATA_TRACKER_TYPE_GUEST = 'guest';
export const DATA_TRACKER_TYPE_STUDENT = 'student';
export const DATA_TRACKER_TIMESTAMP = 'timestamp';
export const DATA_TRACKER_TIMEZONE_OFFSET = 'timezone_offset';
export const DATA_TRACKER_HASH_LINK = 'hash_link';
export const DATA_TRACKER_IDENTITY_ID = 'identity_id';
export const DATA_TRACKER_INTERNAL = 'internal';
export const DATA_TRACKER_AUTHENTICATION_PROVIDER_ID =
  'authentication_provider_id';
export const DATA_TRACKER_ACCOUNT_TYPE = 'account_type';
export const DATA_TRACKER_ACCOUNT_STATUS = 'account_status';
export const DATA_TRACKER_ACCOUNT_PLAN = 'account_plan';
export const DATA_TRACKER_ACCOUNT_FREE_TRIAL = 'account_free_trial';
export const DATA_TRACKER_SCHOOL_ROLES = 'school_roles';
export const DATA_TRACKER_SCHOOL_ID = 'school_id';
export const DATA_TRACKER_SCHOOL_TYPE = 'school_type';
export const DATA_TRACKER_TYPE_OF_SCHOOL = 'type_of_school';
export const DATA_TRACKER_SCHOOL_SECTOR = 'school_sector';
export const DATA_TRACKER_SCHOOL_STATE = 'school_state';
export const DATA_TRACKER_SCHOOL_COUNTRY = 'school_country';
export const DATA_TRACKER_SCREEN_RESOLUTION = 'screen_resolution';
export const DATA_TRACKER_REFERRER = 'referrer';
export const DATA_TRACKER_USER_AGENT = 'user_agent';
export const DATA_TRACKER_BILLING_ACCOUNT_NAME = 'billing_account_name';
export const DATA_TRACKER_APPLICATION_VERSION = 'application_version';
export const DATA_TRACKER_PRESENTATION_SESSION_ID = 'presentation_session_id';

export const DATA_TRACKER_ENCODE_SINGLE_QUOTE = 'encodeSingleQuote';
export const DATA_TRACKER_ENCODE_SPECIAL_CHARS = 'encodeSpecialChars';

export const DATA_TRACKER_SSO_PREFIX = 'sso-';

export type DataTrackerUserType =
  | typeof DATA_TRACKER_TYPE_TEACHER
  | typeof DATA_TRACKER_TYPE_GUEST
  | typeof DATA_TRACKER_TYPE_STUDENT;

export type DataEventAttrs = {
  [key: string]: any;
};

export type DataTrackerEventData = {
  [DATA_TRACKER_EVENT_TARGET_TYPE]: string;
  [DATA_TRACKER_EVENT_TARGET_IDENTIFIER]?: string | number;
} & DataEventAttrs;

export type DataEventProp = {
  category: string;
  target: DataTrackerEventData;
};

export type DataTrackerEvent = {
  event_action: string;
  event_category: string;
  event_data: DataTrackerEventData;
};

export type DataTrackerPageParams = { [key: string]: string[] };

export type DataTrackerPayload = {
  [DATA_TRACKER_HASH_LINK]: string;
  [DATA_TRACKER_TIMESTAMP]: string;
  [DATA_TRACKER_TIMEZONE_OFFSET]: string;
  [DATA_TRACKER_PAGE_PATH]: string;
  [DATA_TRACKER_PAGE_PARAMS]: string;

  [DATA_TRACKER_SESSION_ID]: string;
  [DATA_TRACKER_CLIENT_ID]: string;
  [DATA_TRACKER_USER_TYPE]: DataTrackerUserType;
  [DATA_TRACKER_USER_POSITION]?: InquisitiveUserPosition | null;
  [DATA_TRACKER_USER_ID]?: number;
  [DATA_TRACKER_SUBSCRIPTION_ID]?: number;
  [DATA_TRACKER_CLASS_ID]?: number;
  [DATA_TRACKER_CLASS_USER_ID]?: number;
  [DATA_TRACKER_IDENTITY_ID]?: string;
  [DATA_TRACKER_INTERNAL]?: boolean;
  [DATA_TRACKER_AUTHENTICATION_PROVIDER_ID]?: string;
  [DATA_TRACKER_ACCOUNT_TYPE]?: 'individual' | 'school';
  [DATA_TRACKER_ACCOUNT_STATUS]?: 'active' | 'inactive';
  [DATA_TRACKER_ACCOUNT_PLAN]?: string;
  [DATA_TRACKER_ACCOUNT_FREE_TRIAL]?: boolean;
  [DATA_TRACKER_SCHOOL_ROLES]?: InquisitiveRole[];
  [DATA_TRACKER_SCHOOL_ID]?: number;
  [DATA_TRACKER_SCHOOL_TYPE]?: string | null;
  [DATA_TRACKER_SCHOOL_SECTOR]?: string | null;
  [DATA_TRACKER_SCHOOL_STATE]?: string | null;
  [DATA_TRACKER_SCHOOL_COUNTRY]?: string | null;
  [DATA_TRACKER_TYPE_OF_SCHOOL]?: string | null;
  [DATA_TRACKER_SCREEN_RESOLUTION]: string;
  [DATA_TRACKER_REFERRER]: string;
  [DATA_TRACKER_USER_AGENT]: string;
  [DATA_TRACKER_BILLING_ACCOUNT_NAME]: string | null;
  [DATA_TRACKER_APPLICATION_VERSION]: string;
  [DATA_TRACKER_PRESENTATION_SESSION_ID]: string | null;
};

export const INQ_DATA_CLIENT_ID = `inq-data-client-id:${process.env.REACT_APP_REGION}`;
export const INQ_DATA_SESSION_ID = `inq-data-session-id:${process.env.REACT_APP_REGION}`;
export const INQ_DATA_PRESENTATION_SESSION_ID = `inq-data-presentation-session-id:${process.env.REACT_APP_REGION}`;

export const DATA_TRACKER_BLACKLIST = [
  'email',
  'password',
  'firstName',
  'lastName',
  'classPasscode',
  'code',
];

export const DATA_TRACKER_SESSION_TIMEOUT_MILLISECONDS = 1000 * 60 * 30; // 30 mins

export const DATA_TRACKER_INTERNAL_USER_REGEX = /test@|@inquisitive.(com|edu.au)/;

export const DATA_TRACKER_MANUAL_PAGEVIEW_CONTENT_TYPES = [WEB_TOOL, LINK];
export const DATA_TRACKER_DOWNLOADABLE_STIMULUS_RESOURCES = [
  DOCUMENT,
  EBOOK_PDF,
  IMAGE,
];
