import styled, { css } from 'styled-components';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { rem, mediaDown } from 'approot/shared/style';
import {
  grey6,
  grey8,
  grey9,
  grey10,
  red8,
  red9,
  red10,
  purple8,
  purple9,
} from './colours.style';
import { standardFocusState } from './borders.style';
import { spacing4, spacing20, spacing16 } from './spacing.style';

export const FONT_CALIBRE_SCHOOL = 'CalibreSchoolWeb';
export const FONT_NSW_HANDWRITING = 'NSWHandwriting';
export const FONT_TIMES_NEW_ROMAN = 'Times New Roman';
export const FONT_GOOGLE_NSW_HANDWRITING = 'Edu NSW ACT Foundation';
export const FONT_GOOGLE_ROBOTO = 'Roboto';
export const FONT_GOOGLE_MONTSERRAT = 'Montserrat';

export const fontFamilySansSerif = `${FONT_CALIBRE_SCHOOL}, Calibre, CalibreSchool,
'Segoe UI', Roboto, 'Helvetica Neue', Arial, -apple-system, system-ui,
BlinkMacSystemFont, sans-serif`;
export const fontFamilyBase = fontFamilySansSerif;

export const fontWeightLighter = 100;
export const fontWeightLight = 300;
export const fontWeightNormal = 400;
export const fontWeightMedium = 500;
export const fontWeightBold = 600;
export const fontWeightBolder = 700;

export const fontSize12 = 12;
export const fontSize14 = 14;
export const fontSize16 = 16;
export const fontSize18 = 18;
export const fontSize20 = 20;
export const fontSize22 = 22;
export const fontSize24 = 24;
export const fontSize28 = 28;
export const fontSize30 = 30;
export const fontSize38 = 38;
export const fontSize46 = 46;
export const fontSize56 = 56;

export const fontSize12LineHeight = 20;
export const fontSize14LineHeight = 22;
export const fontSize16LineHeight = 24;
export const fontSize18LineHeight = 26;
export const fontSize20LineHeight = 28;
export const fontSize24LineHeight = 32;
export const fontSize30LineHeight = 38;
export const fontSize38LineHeight = 46;
export const fontSize46LineHeight = 54;
export const fontSize56LineHeight = 64;

export const font12 = css`
  font-size: ${rem(fontSize12)};
  line-height: ${rem(fontSize12LineHeight)};
`;

export const font14 = css`
  font-size: ${rem(fontSize14)};
  line-height: ${rem(fontSize14LineHeight)};
`;

export const font16 = css`
  font-size: ${rem(fontSize16)};
  line-height: ${rem(fontSize16LineHeight)};
`;

export const font18 = css`
  font-size: ${rem(fontSize18)};
  line-height: ${rem(fontSize18LineHeight)};
`;

export const font18Responsive = css`
  ${mediaDown.sm`
    font-size: ${rem(fontSize16)};
    line-height: ${rem(fontSize16LineHeight)};
  `}

  font-size: ${rem(fontSize18)};
  line-height: ${rem(fontSize18LineHeight)};
`;

export const font20 = css`
  font-size: ${rem(fontSize20)};
  line-height: ${rem(fontSize20LineHeight)};
`;

export const font22 = css`
  font-size: ${rem(fontSize22)};
  line-height: ${rem(30)};
`;

export const font24 = css`
  font-size: ${rem(fontSize24)};
  line-height: ${rem(fontSize24LineHeight)};
`;

export const font24Responsive = css`
  ${mediaDown.sm`
    font-size: ${rem(fontSize20)};
    line-height: ${rem(fontSize20LineHeight)};
  `}

  font-size: ${rem(fontSize24)};
  line-height: ${rem(fontSize24LineHeight)};
`;

export const font30 = css`
  font-size: ${rem(fontSize30)};
  line-height: ${rem(38)};
`;

export const font30Responsive = css`
  ${mediaDown.sm`
    font-size: ${rem(fontSize24)};
    line-height: ${rem(fontSize24LineHeight)};
  `}

  font-size: ${rem(fontSize30)};
  line-height: ${rem(38)};
`;

export const font38 = css`
  font-size: ${rem(fontSize38)};
  line-height: ${rem(fontSize38LineHeight)};
`;

export const font46 = css`
  font-size: ${rem(fontSize46)};
  line-height: ${rem(fontSize46LineHeight)};
`;

export const font56 = css`
  font-size: ${rem(fontSize56)};
  line-height: ${rem(fontSize56LineHeight)};
`;

export function getFontSize(size: number) {
  switch (size) {
    case fontSize16:
      return font16;
    case fontSize18:
      return font18;
    case fontSize20:
      return font20;
    case fontSize22:
      return font22;
    case fontSize24:
      return font24;
    case fontSize30:
      return font30;
    default:
      return fontSize24;
  }
}

export type FontWeight = 'light' | 'normal' | 'medium' | 'bold';

export type HeadingProps = {
  colour?: string;
  $fontWeight?: FontWeight;
  marginBottom?: number;
  marginTop?: number;
  textAlignCenter?: boolean;
};

function getMarginBottom(margin = spacing16) {
  return `margin-bottom: ${rem(margin)}`;
}
function getMarginTop(margin = 0) {
  return `margin-top: ${rem(margin)}`;
}
function getTextAlign(textAlignCenter: boolean | undefined) {
  return `text-align: ${textAlignCenter ? 'center' : ''}`;
}

export function getFontWeight(fontWeight: FontWeight = 'normal') {
  switch (fontWeight) {
    case 'light':
      return `font-weight: ${fontWeightLight}`;
    case 'medium':
      return `font-weight: ${fontWeightMedium}`;
    case 'normal':
      return `font-weight: ${fontWeightNormal}`;
    case 'bold':
      return `font-weight: ${fontWeightBold}`;
  }
}

const headingBaseCss = css<HeadingProps>`
  color: ${({ colour = grey10 }) => colour};
  ${({ marginBottom }) => getMarginBottom(marginBottom)};
  ${({ marginTop }) => getMarginTop(marginTop)};
  ${({ textAlignCenter }) => getTextAlign(textAlignCenter)};
  ${({ $fontWeight = 'medium' }) => getFontWeight($fontWeight)};
`;

export const Heading46 = styled.h1<HeadingProps>`
  ${font46}
  ${headingBaseCss}
`;

export const Heading38 = styled.h1<HeadingProps>`
  ${font38}
  ${headingBaseCss}
`;

export const Heading30 = styled.h1<HeadingProps>`
  ${font30}
  ${headingBaseCss}
`;

export const Heading30Responsive = styled.h1<HeadingProps>`
  ${font30Responsive}
  ${headingBaseCss}
`;

export const Heading24 = styled.h1<HeadingProps>`
  ${font24}
  ${headingBaseCss}
`;

export const Heading24Responsive = styled.h1<HeadingProps>`
  ${font24Responsive}
  ${headingBaseCss}
`;

export const Heading20 = styled.h1<HeadingProps>`
  ${font20}
  ${headingBaseCss}
`;

export const Heading18 = styled.h1<HeadingProps>`
  ${font18}
  ${headingBaseCss}
`;

export const Heading18Responsive = styled.h1<HeadingProps>`
  ${font18Responsive}
  ${headingBaseCss}
`;

export const Heading16 = styled.h1<HeadingProps>`
  ${font16}
  ${headingBaseCss}
`;

export type ParagraphProps = {
  colour?: string;
  marginBottom?: number;
  marginTop?: number;
  textAlignCenter?: boolean;
};

export const paragraphBase = css<ParagraphProps>`
  ${font16}
  color: ${({ colour = grey8 }) => colour};
  ${({ marginTop }) => getMarginTop(marginTop)};
  ${({ marginBottom }) => getMarginBottom(marginBottom)};
  ${({ textAlignCenter }) => getTextAlign(textAlignCenter)};
`;

export const PStyled = styled.p<ParagraphProps>`
  ${paragraphBase}
`;

export const SmallStyled = styled.small<ParagraphProps>`
  ${font14}
  ${({ colour }) => (colour ? `color: ${colour};` : '')};
  ${({ marginTop }) => getMarginTop(marginTop)};
  ${({ marginBottom }) => getMarginBottom(marginBottom)};
  ${({ textAlignCenter }) => getTextAlign(textAlignCenter)};
`;

export const listBase = css`
  ${font16}
  color: ${grey8};
  padding: 0 0 0 ${rem(spacing20)};
  li {
    margin-bottom: ${rem(spacing4)};
  }
`;

export const UlStyled = styled.ul`
  ${listBase}
`;

export const OlStyled = styled.ol`
  ${listBase}
`;

type AposProps = {
  children: ReactNode;
};

export const AposStyled = ({ children }: AposProps) => (
  <>
    <span aria-hidden>&#8216;</span>
    {children}
    <span aria-hidden>&#8217;</span>
  </>
);

export const QuotStyled = ({ children }: AposProps) => (
  <>
    <span aria-hidden>&#8220;</span>
    {children}
    <span aria-hidden>&#8221;</span>
  </>
);

export const anchorLinkBase = css`
  color: ${grey9};
  text-decoration: none;

  &:hover {
    color: ${grey8};
    text-decoration: underline;
  }

  ${standardFocusState};

  &:focus {
    border-radius: ${rem(4)};
    color: ${grey9};
  }

  &:active {
    color: ${grey10};
  }

  &.disabled,
  &.disabled:hover,
  &:disabled,
  &:disabled:hover {
    color: ${grey6};
    text-decoration: none;
    cursor: not-allowed;
  }
`;

export const anchorErrorBase = css`
  color: ${red8};

  &:hover,
  &:focus:hover {
    color: ${red9};
  }

  ${standardFocusState};

  &:focus {
    border-radius: ${rem(4)};
    color: ${red8};
  }

  &:active {
    color: ${red10};
  }

  &.disabled,
  &.disabled:hover {
    color: ${grey6};
    text-decoration: none;
    cursor: not-allowed;
  }
`;

export const AnchorLinkErrorStyled = styled(Link)`
  ${anchorErrorBase};
`;

export type LinkProps = {
  $fontWeight?: FontWeight;
};

export const linkBase = css<LinkProps>`
  ${font16}
  ${anchorLinkBase}

  ${({ $fontWeight = 'medium' }) => getFontWeight($fontWeight)};
  text-decoration: underline;
`;

export const AnchorStyled = styled.a<LinkProps>`
  ${linkBase}
`;

export const LinkStyled = styled(Link)<LinkProps>`
  ${linkBase}
`;

export const purpleLinkBase = css`
  ${font14}
  ${standardFocusState}

  color: ${purple9};

  &:hover {
    color: ${purple8};
  }  

  &:focus {
    border-radius: ${rem(4)};
    color: ${purple8};
  }

  &:active {
    color: ${purple8};
  }

  &.disabled,
  &.disabled:hover {
    color: ${grey6};
    text-decoration: none;
    cursor: not-allowed;
  }

  ${standardFocusState}
`;

export type PurpleLinkProps = {
  $fontInherit?: boolean;
};

export const PurpleLinkStyled = styled(Link)<PurpleLinkProps>`
  ${purpleLinkBase}
  ${({ $fontInherit }) => $fontInherit && `font-size: inherit;`}
`;

export const BoldStyled = styled(PStyled)<ParagraphProps>`
  font-weight: ${fontWeightMedium};
`;

export const TextCenter = styled.div`
  text-align: center;
`;

export const ellipsisCss = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const AbbrStyled = styled.abbr`
  text-decoration: none !important;
`;
