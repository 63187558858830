import cloneDeep from 'lodash/cloneDeep';
import { InMemoryCache } from '@apollo/client';
import { generatePath, Path } from 'react-router-dom';

import { LESSON, Subject } from 'global/constants';
import { FilterSubject, LessonType } from 'webclient.constants';
import {
  ContentBrowserVariables,
  ContentBrowser_contentBrowser,
  ContentBrowser_contentBrowser_cursors,
  ContentBrowser_contentBrowser_topics,
  ContentBrowser_contentBrowser_topics_units_lessons,
  ContentBrowser_contentBrowser_topics_units_assessments,
} from './__generated__/ContentBrowser';
import { ContentBrowserPagination } from './__generated__/ContentBrowserPagination';
import { CONTENT_BROWSER_PAGINATION } from './content-browser.graphql';
import {
  InquisitiveCurriculum,
  InquisitiveSubject,
  InquisitiveYears,
} from 'global/graphql/scalars.type';
import { getUserClassPath } from 'approot/classes/classes.apollo';
import { PUBLIC_SUBJECT } from 'global/public-routes.constants';

export const generateContentBrowserLink = ({
  subject,
  years,
}: {
  subject: Subject;
  years?: string[];
}): Path => {
  const pathName = `${getUserClassPath(
    generatePath(PUBLIC_SUBJECT, {
      subject,
    })
  )}`;

  const searchParams = new URLSearchParams();

  const sortedYears = years?.sort((a, b) => {
    const yearA = parseInt(a);
    const yearB = parseInt(b);
    return yearA - yearB;
  });

  sortedYears?.forEach(year => {
    searchParams.append('years', year);
  });

  return {
    pathname: pathName,
    search: searchParams.toString(),
    hash: '',
  };
};

export function getContentBrowserParams({
  years,
  subjects,
  subjectOptions,
  curriculum,
  curriculumVersion,
  isEditing,
  topicIds = [],
  initialTopics = 1,
}: {
  years: InquisitiveYears[];
  subjects: InquisitiveSubject[];
  subjectOptions: InquisitiveSubject[];
  curriculum: InquisitiveCurriculum[];
  curriculumVersion: string[];
  isEditing: boolean;
  topicIds: number[];
  initialTopics?: number;
}) {
  return {
    years: years,
    subjects: subjects.length === 0 ? subjectOptions : subjects,
    curriculums: curriculum.length > 0 ? curriculum : [],
    outcomeCurriculumVersions:
      curriculumVersion.length > 0 ? curriculumVersion : [],
    includingDraft: isEditing,
    includingComingSoon: true,
    includingFuture: isEditing,
    includingEmptyTopic: isEditing,
    initialTopics,
    topicIds,
  };
}

export function isAtEndOfData(
  data: ContentBrowser_contentBrowser_cursors[],
  cursor: number,
  year: InquisitiveYears
) {
  const yearIndex = data.findIndex(d => d.year === year);
  return (
    yearIndex === -1 ||
    (data[yearIndex] &&
      !data[yearIndex].topics[cursor + 1] &&
      !data[yearIndex + 1])
  );
}

function getNextTopicIds(
  data: ContentBrowser_contentBrowser_cursors[],
  cursor: number,
  currentYear: InquisitiveYears,
  numberOfTopics: number = 1
): {
  cursor: number;
  year: InquisitiveYears;
  topicIds: number[];
  isEndOfList: boolean;
} {
  const topicIds: number[] = [];
  let newCursor = cursor;
  let isEndOfList = false;

  // find the year to start the search
  const yearCursor = data.findIndex(d => d.year === currentYear);

  if (yearCursor > -1) {
    let topics = data[yearCursor].topics;

    // is end of topic list
    const isEndOfTopicList = cursor === topics.length;
    const nextYearGroup = data[yearCursor + 1];
    if (isEndOfTopicList && nextYearGroup) {
      return getNextTopicIds(data, 0, nextYearGroup.year, numberOfTopics);
    }

    for (let i = cursor; i < numberOfTopics + cursor; i++) {
      if (topics[i]) {
        topicIds.push(topics[i].id);
        newCursor = i;
      } else {
        if (!data[yearCursor + 1]) {
          isEndOfList = true;
        }
      }
    }
  }

  return {
    cursor: newCursor,
    year: currentYear,
    topicIds: topicIds,
    isEndOfList,
  };
}

export function getNextData(
  data: ContentBrowser_contentBrowser_cursors[],
  currentCursor: number,
  currentYear: InquisitiveYears,
  numberOfTopics: number = 1
) {
  return getNextTopicIds(data, currentCursor, currentYear, numberOfTopics);
}

export function removeLessonFromContentBrowser(
  topics: ContentBrowser_contentBrowser_topics[],
  topicId: number,
  id: number,
  type: LessonType
) {
  const key = type === LESSON ? 'lessons' : 'assessments';
  const updatedTopics = cloneDeep(topics);

  for (let i = 0; i < updatedTopics.length; i++) {
    for (let j = 0; j < updatedTopics[i].units.length; j++) {
      const unit = updatedTopics[i].units[j];
      if (unit.topicId === topicId) {
        const removeIdx = unit[key].findIndex(
          (
            item:
              | ContentBrowser_contentBrowser_topics_units_lessons
              | ContentBrowser_contentBrowser_topics_units_assessments
          ) => item.id === id
        );
        if (removeIdx !== -1) {
          unit[key].splice(removeIdx, 1);
          return updatedTopics;
        }
      }
    }
  }

  return topics;
}

export function shouldSkipQuery(
  isFilterDefined: boolean,
  isEditMode: boolean,
  yearsFilters: InquisitiveYears[],
  subjectFilters: FilterSubject[],
  canFilterYears: boolean = true,
  canFilterSubjects: boolean = true
) {
  if (isEditMode) {
    // if no filters are applied
    return (
      (canFilterYears && yearsFilters.length === 0) ||
      (canFilterSubjects && subjectFilters.length === 0)
    );
  }

  if (!isFilterDefined) {
    return true;
  }

  return false;
}

export function isEndOfContent(
  isLoading: boolean,
  isEditing: boolean,
  showFilterMessage: boolean,
  dataLength: number,
  cursor: number | null
) {
  if (isEditing) {
    return !isLoading && !showFilterMessage && dataLength > 0;
  }
  return !isLoading && dataLength > 0 && cursor === null;
}

export function updatePaginationCursors(
  client: InMemoryCache,
  contentBrowserCursor: number | null,
  contentBrowserCursorYear: string
) {
  client.writeQuery({
    query: CONTENT_BROWSER_PAGINATION,
    data: {
      contentBrowserCursor,
      contentBrowserCursorYear,
    },
  });
}

export function contentBrowserMerge(
  existing: ContentBrowser_contentBrowser | undefined,
  incoming: ContentBrowser_contentBrowser,
  cache: InMemoryCache,
  variables: ContentBrowserVariables
) {
  // this can occur when manually writing to the cache after adding to planner
  if (!variables.input) return incoming;

  const clientPagination = cache.readQuery<ContentBrowserPagination>({
    query: CONTENT_BROWSER_PAGINATION,
  });
  const cursor = clientPagination?.contentBrowserCursor || 0;
  const cursorYear = clientPagination?.contentBrowserCursorYear || '0';

  if (variables.input.topicIds?.length === 0) {
    // incoming data for new query
    if (incoming.cursors.length === 0) {
      return incoming;
    }

    let initialCursorYear = cursorYear;
    let initialCursor = cursor;
    let count = 0;
    const topicCount = incoming.topics.length - 1;

    loop1: for (let i = 0; i < incoming.cursors.length; i++) {
      for (let j = 0; j < incoming.cursors[i].topics.length; j++) {
        if (count === topicCount) {
          initialCursor = j;
          initialCursorYear = incoming.cursors[i].year;
          break loop1;
        }
        count++;
      }
    }

    const isEnd = isAtEndOfData(
      incoming.cursors,
      initialCursor,
      initialCursorYear as InquisitiveYears
    );
    updatePaginationCursors(
      cache,
      isEnd ? null : initialCursor,
      initialCursorYear
    );
    return incoming;
  }

  // incoming data when loading more content
  const existingTopics = existing?.topics.slice(0);
  if (
    isAtEndOfData(
      existing?.cursors || [],
      cursor,
      cursorYear as InquisitiveYears
    )
  ) {
    updatePaginationCursors(cache, null, cursorYear);
  }
  return {
    cursors: existing?.cursors,
    hasContentInAllCurriculum: existing?.hasContentInAllCurriculum,
    topics: existingTopics?.concat(incoming.topics ? incoming.topics : []),
  };
}
