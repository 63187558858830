import { ViewMode } from 'approot/shared/filters/filters.constants';
import { GetStartupData_startupData_subjects } from 'approot/shared/graphql/startup-data/__generated__/GetStartupData';

export const SUBJECT_ABOUT = 'about';

export type SubjectBrowserContextType = {
  subject: GetStartupData_startupData_subjects;
  viewMode: ViewMode;
  isFetchMoreError: boolean;
  setIsFetchMoreError: (isFetchMoreError: boolean) => void;
};

export const SUBJECT_TABS = [
  {
    label: 'Content',
    value: 'content',
  },
  {
    label: 'About',
    value: 'about',
  },
];
