import { ApolloError } from '@apollo/client';
import { ServerError } from 'apollo-link-http-common';

import { isOnSignInRefreshSessionPage } from 'approot/shared/signin/signin-refresh-session/signin-refresh-session-form.utils';
import {
  ERROR_MESSAGE_CLASS_TO_DO_TOKEN,
  ERROR_MESSAGE_FAILED_AUTHENTICATION,
  ERROR_MESSAGE_ALREADY_LOGGED_IN,
  ERROR_MESSAGE_CLASS_NOT_FOUND,
  ERROR_MESSAGE_SESSION_IS_STALE,
  ERROR_MESSAGE_STUDENT_SESSION_IS_STALE,
  APOLLO_ERROR_NOT_FOUND,
} from 'global/constants';
import { getServerErrorMessage } from 'lib/apollo.utils';

export function isFailedToFetch(error?: ApolloError) {
  return error?.networkError?.message.includes('Failed to fetch');
}

export function isAlreadyLoggedIn(error?: ApolloError) {
  return (error?.networkError as ServerError)?.result?.errors?.[0]?.message.includes(
    ERROR_MESSAGE_ALREADY_LOGGED_IN
  );
}

export function getErrorCode(error?: ApolloError): number | string | undefined {
  // Check network error status code
  if (error?.networkError) {
    return (error.networkError as ServerError)?.statusCode;
  }

  // Check graphQL error extensions
  if (error?.graphQLErrors?.[0]?.extensions?.code) {
    return error.graphQLErrors[0].extensions.code;
  }

  return undefined;
}

export function isNotFound(error?: ApolloError) {
  const errorCode = getErrorCode(error);
  return (
    error &&
    (error.message.includes('Not Found') ||
      error.message.includes('not-found') ||
      errorCode === 404 ||
      errorCode === APOLLO_ERROR_NOT_FOUND)
  );
}

export function isUnauthorised(error?: ApolloError) {
  const errorCode = getErrorCode(error);
  return (
    error?.networkError?.message.includes('unauthorised') || errorCode === 401
  );
}

export function isFailedClassToDoAuth(error?: ApolloError) {
  const errorMessage = getServerErrorMessage(error) || (error && error.message);
  return (
    !!errorMessage && errorMessage.includes(ERROR_MESSAGE_FAILED_AUTHENTICATION)
  );
}

export function getDebugInfo() {
  const navigator = window.navigator as any;
  const connection = navigator && navigator.connection;
  const info = [
    Date(),
    window.navigator.userAgent,
    navigator.onLine ? 'isOnline' : 'isOffline',
  ];

  if (connection) {
    if (connection.downlink) info.push(connection.downlink);
    if (connection.type) info.push(connection.type);
  }
  return info.join(', ');
}

export function isExpiredClassToDoTokenServerError(error?: ServerError) {
  return error?.result?.message === ERROR_MESSAGE_CLASS_TO_DO_TOKEN;
}

export function isNoClassError(error?: ServerError) {
  return error?.result?.message === ERROR_MESSAGE_CLASS_NOT_FOUND;
}

export function isStaleSessionServerError(error?: ServerError) {
  return (
    error?.result?.message === ERROR_MESSAGE_SESSION_IS_STALE &&
    !isOnSignInRefreshSessionPage()
  );
}

export function isStaleStudentSessionServerError(error?: ServerError) {
  return (
    error?.result?.message === ERROR_MESSAGE_STUDENT_SESSION_IS_STALE &&
    !isOnSignInRefreshSessionPage()
  );
}
