import { makeVar } from '@apollo/client';
import { ModalKey } from 'approot/shared/modal/modal.constants';

export const globalModalVar = makeVar<ModalKey>(ModalKey.NONE);

export const getGlobalModal = () => globalModalVar();
export const setGlobalModal = (key: ModalKey) => {
  globalModalVar(key);
};
export const closeGlobalModal = () => {
  globalModalVar(ModalKey.NONE);
};
