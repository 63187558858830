import { useReactiveVar } from '@apollo/client';
import { ModalKey } from 'approot/shared/modal/modal.constants';
import { closeGlobalModal, globalModalVar } from './global-modals.apollo';

export const useGlobalModal = () => {
  const modalState = useReactiveVar(globalModalVar);
  return {
    globalModal: modalState || ModalKey.NONE,
    closeGlobalModal: closeGlobalModal,
  };
};
