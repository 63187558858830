export const EVENT_CATEGORY_CLASS_TODO = 'class-todo';
export const EVENT_CATEGORY_CONTENT_BROWSER = 'content-browser';
export const EVENT_CATEGORY_SUBJECT_PAGE = 'subject-page';
export const EVENT_CATEGORY_STIMULUS_PAGE = 'stimulus-page';
export const EVENT_CATEGORY_SCOPE_AND_SEQUENCE = 'scope-and-sequence';
export const EVENT_CATEGORY_BLOG = 'blog';
export const EVENT_CATEGORY_SEARCH = 'search';
export const EVENT_CATEGORY_SELECT_PAGE = 'select-page';
export const EVENT_CATEGORY_WORKSHEETS = 'worksheets';
export const EVENT_CATEGORY_LESSON = 'lesson';
export const EVENT_CATEGORY_PROGRAM = 'program';
export const EVENT_CATEGORY_RUBRIC = 'unit-rubric';
export const EVENT_CATEGORY_AE_RUBRIC = 'ae-rubric';
export const EVENT_CATEGORY_VIC_RUBRIC = 'vic-rubric';
export const EVENT_CATEGORY_SOCIAL_EMOTIONAL_RUBRIC =
  'social-and-emotional-rubric';
export const EVENT_CATEGORY_DIFFERENTIATED_ASSESSMENT =
  'differentiated-assessment';
export const EVENT_CATEGORY_ASSESSMENT = 'assessment';
export const EVENT_CATEGORY_SELF_ASSESSMENT = 'self-assessment';
export const EVENT_CATEGORY_SUMMATIVE_ASSESSMENT = 'summative-assessment';
export const EVENT_CATEGORY_FORMATIVE_ASSESSMENT = 'formative-assessment';
export const EVENT_CATEGORY_LESSON_PLAN = 'lesson-plan';
export const EVENT_CATEGORY_ANSWER_SHEET = 'answersheet';
export const EVENT_CATEGORY_ASSESSMENT_FOR_LEARNING_TOOL =
  'assessment-for-learning-tool';
export const EVENT_CATEGORY_SUPPORT_DOCUMENT = 'support';
export const EVENT_CATEGORY_EXTENSION_DOCUMENT = 'extension';
export const EVENT_CATEGORY_VOCABULARY_EN_DOCUMENT = 'vocabulary-en';
export const EVENT_CATEGORY_VOCABULARY_ES_DOCUMENT = 'vocabulary-es';
export const EVENT_CATEGORY_TEACHER_INFORMATION_DOCUMENT =
  'teacher-information';
export const EVENT_CATEGORY_LESSON_RUBRIC_DOCUMENT = 'lesson-rubric';
export const EVENT_CATEGORY_CONTENT = 'content';
export const EVENT_CATEGORY_CONTENT_PRODUCTION = 'content-production';
export const EVENT_CATEGORY_CONTENT_PREVIEW = 'content-preview';
export const EVENT_CATEGORY_PLANNER = 'planner';
export const EVENT_CATEGORY_PLANNER_RECEIVE = 'planner-receive';
export const EVENT_CATEGORY_PLANNER_ADD_UNIT = 'planner-add-unit';
export const EVENT_CATEGORY_PLANNER_TERM = 'terms';
export const EVENT_CATEGORY_ACCOUNT = 'account';
export const EVENT_CATEGORY_PROFILE = 'profile';
export const EVENT_CATEGORY_AUTHENTICATION = 'authentication';
export const EVENT_CATEGORY_INFO = 'info';
export const EVENT_CATEGORY_CLASSES = 'classes';
export const EVENT_CATEGORY_NOTIFICATION = 'notification';
export const EVENT_CATEGORY_ANNOTATIONS = 'annotations';
export const EVENT_CATEGORY_ANNOTATIONS_MODAL = 'annotations-modal';
export const EVENT_CATEGORY_JOIN = 'join';
export const EVENT_CATEGORY_JOIN_MODAL = 'join-modal';
export const EVENT_CATEGORY_TRIAL = 'trial';
export const EVENT_CATEGORY_NAV = 'nav';
export const EVENT_CATEGORY_SIDE_NAV_PRIMARY = 'side-nav-primary';
export const EVENT_CATEGORY_SIDE_NAV_SECONDARY = 'side-nav-secondary';
export const EVENT_CATEGORY_NAV_CLASS_MENU = 'nav-class-menu';
export const EVENT_CATEGORY_TOP_NAV_MENU = 'top-nav-menu';
export const EVENT_CATEGORY_STUDENTS = 'students';
export const EVENT_CATEGORY_ERROR = 'error';
export const EVENT_CATEGORY_ACTIVITY_BUILDER = 'activity-builder';
export const EVENT_CATEGORY_ACTIVITY_COMPONENT = 'activity-component';
export const EVENT_CATEGORY_STIMULUS_COMPONENT = 'stimulus-component';
export const EVENT_CATEGORY_AUTHENTICATION_TLS = 'authentication-tls';
export const EVENT_CATEGORY_LESSON_NAV = 'lesson-nav';
export const EVENT_CATEGORY_ACTIVITIES = 'activities';

export const EVENT_ACTION_ADD = 'add';
export const EVENT_ACTION_REMOVE = 'remove';
export const EVENT_ACTION_FILTER = 'filter';
export const EVENT_ACTION_ADD_FILTER = `add-${EVENT_ACTION_FILTER}`;
export const EVENT_ACTION_REMOVE_FILTER = `remove-${EVENT_ACTION_FILTER}`;
export const EVENT_ACTION_SELECT_RESULT = 'select-result';
export const EVENT_ACTION_SELECT = 'select';
export const EVENT_ACTION_SELECT_PAGE = 'select-page';
export const EVENT_ACTION_DOWNLOAD = 'download';
export const EVENT_ACTION_COPY_LINK = 'copy-link';
export const EVENT_ACTION_COMPLETE = 'complete';
export const EVENT_ACTION_REMOVE_COMPLETE = 'remove-complete';
export const EVENT_ACTION_EDIT_COMPLETE = 'edit-complete';
export const EVENT_ACTION_MOVE_TERM = 'move-term';
export const EVENT_ACTION_REORDER = 'reorder';
export const EVENT_ACTION_CREATE = 'create';
export const EVENT_ACTION_RESET = 'reset';
export const EVENT_ACTION_EDIT = 'edit';
export const EVENT_ACTION_PREVIEW = 'preview';
export const EVENT_ACTION_VIEW = 'view';
export const EVENT_ACTION_GO_TO = 'go-to';
export const EVENT_ACTION_SWITCH = 'switch';
export const EVENT_ACTION_DUPLICATE = 'duplicate';
export const EVENT_ACTION_ARCHIVE = 'archive';
export const EVENT_ACTION_UNARCHIVE = 'unarchive';
export const EVENT_ACTION_DEFAULT = 'default';
export const EVENT_ACTION_REMOVE_NOTES = 'remove-notes';
export const EVENT_ACTION_ADD_NOTES = 'add-notes';
export const EVENT_ACTION_EDIT_NOTES = 'edit-notes';
export const EVENT_ACTION_OPEN = 'open';
export const EVENT_ACTION_CLOSE = 'close';
export const EVENT_ACTION_EXIT = 'exit';
export const EVENT_ACTION_FOLLOW = 'follow';
export const EVENT_ACTION_DISMISS = 'dismiss';
export const EVENT_ACTION_NOT_MY_CLASS = 'not-my-class';
export const EVENT_ACTION_SIGN_IN = 'sign-in';
export const EVENT_ACTION_SIGN_OUT = 'sign-out';
export const EVENT_ACTION_SESSION_TAKEN = 'session-taken';
export const EVENT_ACTION_SWITCH_TAB = 'switch-tab';
export const EVENT_ACTION_START_RECEIVE = 'start-receive';
export const EVENT_ACTION_RECEIVE_SELECT = 'add-selection';
export const EVENT_ACTION_RECEIVE_DESELECT = 'remove-selection';
export const EVENT_ACTION_RECEIVE = 'receive';
export const EVENT_ACTION_ATTACH_STIMULUS = 'attach-stimulus';
export const EVENT_ACTION_EXTERNAL_LINK = 'external-link';
export const EVENT_ACTION_REQUEST_ACCESS_SCHOOL =
  'request-access-school-account';
export const EVENT_ACTION_VALIDATION_ERROR = 'validation-error';
export const EVENT_ACTION_MESSAGE = 'message';
export const EVENT_ACTION_EXCEPTION = 'exception';
export const EVENT_ACTION_EXPAND = 'expand';
export const EVENT_ACTION_SECTION = 'section';
export const EVENT_ACTION_REGISTER_EMAIL = 'register-email';

export const EVENT_TARGET_TYPE_BANNER = 'banner';
export const EVENT_TARGET_TYPE_CURRICULUM = 'curriculum';
export const EVENT_TARGET_TYPE_CURRICULUM_VERSION = 'curriculum-version';
export const EVENT_TARGET_TYPE_SUBJECT = 'subject';
export const EVENT_TARGET_TYPE_YEAR = 'year';
export const EVENT_TARGET_TYPE_CONTENT_TYPE = 'content-type';
export const EVENT_TARGET_TYPE_SCOPE_AND_SEQUENCE = 'scope-and-sequence';
export const EVENT_TARGET_TYPE_INVOICE = 'invoice';
export const EVENT_TARGET_TYPE_DOCUMENT_TYPE = 'document_type';
export const EVENT_TARGET_TYPE_PLANNER_ITEM_GROUP = 'planner_item_group';
export const EVENT_TARGET_TYPE_FORMAT = 'format';
export const EVENT_TARGET_TYPE_STIMULUS = 'stimulus';
export const EVENT_TARGET_TYPE_STIMULUS_TYPE = 'stimulus-type';
export const EVENT_TARGET_TYPE_CLASS = 'class';
export const EVENT_TARGET_TYPE_MODAL = 'modal';
export const EVENT_TARGET_TYPE_FIELD = 'field';
export const EVENT_TARGET_TYPE_NEW_UNIT = 'new-unit';
export const EVENT_TARGET_TYPE_TEACHER = 'teacher';
export const EVENT_TARGET_TYPE_STUDENT = 'student';
export const EVENT_TARGET_TYPE_DRAWER = 'drawer';
export const EVENT_TARGET_TYPE_NOTIFICATION = 'notification';
export const EVENT_TARGET_TYPE_TIP = 'tip';
export const EVENT_TARGET_TYPE_ASSESSMENT = 'assessment';
export const EVENT_TARGET_TYPE_LESSON = 'lesson';
export const EVENT_TARGET_TYPE_UNIT = 'unit';
export const EVENT_TARGET_TYPE_PLANNER = 'planner';
export const EVENT_TARGET_TYPE_RECEIVE_PLANNER = 'shared-planner';
export const EVENT_TARGET_TYPE_RECEIVE_PLANNER_TERM = 'term';
export const EVENT_TARGET_TYPE_RECEIVE_PLANNER_UNIT = 'unit';
export const EVENT_TARGET_TYPE_SCHOOL = 'school';
export const EVENT_TARGET_TYPE_MENU = 'menu';
export const EVENT_TARGET_TYPE_ACTIVITIES = 'activities';
export const EVENT_TARGET_TYPE_NAV_ITEM = 'nav-item';
export const EVENT_TARGET_TYPE_WEBSITE_SEARCH = 'website-search';
export const EVENT_TARGET_TYPE_APPLICATION_ERROR = 'application-error';
export const EVENT_TARGET_TYPE_SECTION = 'section';
export const EVENT_TARGET_TYPE_CLIENT = 'client';
export const EVENT_TARGET_TYPE_ANSWER = 'answer';

export const EVENT_TARGET_DOCUMENT_PAGES = 'pages';
export const EVENT_TARGET_DOCUMENT_FILTERED_PAGES = 'filtered_pages';

export const EVENT_TARGET_IDENTIFIER_STUDENT_INSTRUCTIONS =
  'student-instructions';
export const EVENT_TARGET_IDENTIFIER_PLANNER_ADD_UNITS = 'add-units';
export const EVENT_TARGET_IDENTIFIER_CONTENT_EDIT_COMPLETE =
  'content-edit-complete';
export const EVENT_TARGET_IDENTIFIER_CONTENT_REMOVE_COMPLETE =
  'content-remove-complete';
export const EVENT_TARGET_IDENTIFIER_CANCEL_SUBSCRIPTION =
  'cancel-subscription';
export const EVENT_TARGET_IDENTIFIER_WEBSITE = 'website';
export const EVENT_TARGET_IDENTIFIER_TEACHING_LEARNING_SEQUENCE_CHECKPOINT_INFO =
  'teaching-learning-sequence-checkpoint-info';
export const EVENT_TARGET_IDENTIFIER_TEACHING_LEARNING_SEQUENCE_ENRICHMENT_INFO =
  'teaching-learning-sequence-enrichment-info';
export const EVENT_TARGET_IDENTIFIER_SCOPE_AND_SEQUENCE_SETTINGS = `${EVENT_CATEGORY_SCOPE_AND_SEQUENCE}-settings`;
export const EVENT_TARGET_IDENTIFIER_TEACHING_NOTES = 'teaching-notes';
export const EVENT_TARGET_IDENTIFIER_TEACHING_NOTES_ACTIVITY_NAV_MENU =
  'teaching-notes-activity-nav-menu';

export const EVENT_DATA_POSITION = 'position';
export const EVENT_DATA_PAGEVIEW = 'pageview';
export const EVENT_DATA_COMING_SOON = 'coming_soon';
export const EVENT_DATA_COMING_SOON_PREVIEW = 'coming-soon-preview';
export const EVENT_DATA_NOTE_LENGTH = 'note_length';
export const EVENT_DATA_FOLLOW_ACTION = 'follow_action';
